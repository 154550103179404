export const getPageDetails = () => {
    let pageContents = [
        {
            "name": "datatable1",
            "tag": "srs-datatable",
            "props": {
                "list_title": "User List",
                "list_subtitle": "User Management",
                "headers": [
                    {
                        "name": "Name",
                        "label": "Name",
                        "sortable": true,
                        "inline_sort": true
                    },
                    "Email",
                    "Phone"
                ],
                "rows": [
                    {
                        "id": "1",
                        "Name": "John Doe",
                        "Email": "john.doe@example.com",
                        "Phone": "(123) 456-7890"
                    },
                    {
                        "id": "2",
                        "Name": "Bill McDonald",
                        "Email": "bill.md@example.com",
                        "Phone": "(123) 456-7090"
                    }
                ]
            }
        },
        {
            "name": "Simple Table",
            "tag": "srs-mui-table",
            "props": {
                "display_mode": "simple",
                "list_title": "MUI List",
                "list_subtitle": "User Management",
                "columns": [
                    {
                        "field": "Name",
                        "headerName": "Name"
                    },
                    {
                        "field": "Email",
                        "headerName": "Email",
                        "styles": {"align": "right"}
                    }, 
                    {
                        "field": "Phone",
                        "headerName": "Phone",
                        "styles": {"align": "right"}
                    }
                ],
                "rows": [
                    {
                        "id": "1",
                        "Name": "John Doe",
                        "Email": "john.doe@example.com",
                        "Phone": "(123) 456-7890"
                    },
                    {
                        "id": "2",
                        "Name": "Bill McDonald",
                        "Email": "bill.md@example.com",
                        "Phone": "(123) 456-7090"
                    }
                ]
            }
        },
        {
            "name": "Dense Table",
            "tag": "srs-mui-table",
            "props": {
                "display_mode": "complex",
                "list_title": "MUI List",
                "list_subtitle": "User Management",
                "columns": [
                    {
                        "field": "Name",
                        "headerName": "Name"
                    },
                    {
                        "field": "Email",
                        "headerName": "Email",
                        "styles": {"align": "right"}
                    }, 
                    {
                        "field": "Phone",
                        "headerName": "Phone",
                        "styles": {"align": "right"}
                    }
                ],
                "rows": [
                    {
                        "id": "1",
                        "Name": "John Doe",
                        "Email": "john.doe@example.com",
                        "Phone": "(123) 456-7890"
                    },
                    {
                        "id": "2",
                        "Name": "Bill McDonald",
                        "Email": "bill.md@example.com",
                        "Phone": "(123) 456-7090"
                    }
                ]
            }
        }
    ];
    return {pageContents, pageContext: {}, pageFunctions: {}};
}