import { WrappedReactComponent } from './WrappedReactComponent';
import withStyles from './WithStyleWrapper';
import { componentLookup } from './WebcomponentRegistry';

export const getUniqueComponentsToRegister = (node, names = []) => {
    if(Array.isArray(node)) {
        node.forEach(nodeItem => getUniqueComponentsToRegister(nodeItem, names));
    }else {
        if (names.indexOf(node.tag) == -1) {
            names.push(node.tag);
        }
        if (node.children) {
            node.children.forEach(child => getUniqueComponentsToRegister(child, names));
        }
    }
    return names;     
}

export const register = ( components, baseTheme ) => {
    return new Promise(async (resolve, reject) => {  
        let remainingComponentsToRegister = [];
        let componentPropMetadata = {};
        if(!components || components.length == 0) {
            resolve(componentPropMetadata)
        }else {
            for(let componentIndx in components) {
                remainingComponentsToRegister.push(components[componentIndx]);
            }
            let componentDetails = {};
            
            for(let componentIndx in components) {
                let componentName = components[componentIndx];
                let {WrappedComponent, styles, props, dispatchedEvents} = {WrappedComponent: null, styles: "", props: [], dispatchedEvents: []};
                try {
                    if(componentLookup[componentName]) {
                        let componentData = await import(`../components/${componentLookup[componentName].component}`);
                        WrappedComponent = componentData["WrappedComponent"];
                        styles = componentData["styles"];
                        props = componentData["props"];
                        dispatchedEvents = componentData["dispatchedEvents"];
                        componentDetails[componentName] = { props, dispatchedEvents };
                    }
                }catch(err) {
                    console.error(`Error loading the component ${componentName}`, err);
                }
                props = props || [];
                props.push({name: "context", type: "object", defaultValue: {}});
                props.push({name: "clan", type: "object", defaultValue: []});
                props.push({name: "theme", type: "string", defaultValue: "g90"});
                props.push({name: "elementid", type: "string", defaultValue: ""});
                // props.push({name: "ts", type: "string", defaultValue: ""});
                props.push({name: "activenode", type: "boolean", defaultValue: false});
                props.push({name: "slotname", type: "string", defaultValue: ""});
                props.push({name: "designtime", type: "boolean", defaultValue: false});
                props.push({name: "widgetid", type: "string", defaultValue: ""});
                props.push({name: "misc", type: "object", defaultValue: {}});
                props.push({name: "inlinestyles", type: "object", defaultValue: {}});
                props.push({name: "hoststyles", type: "object", defaultValue: {}});
                props.push({name: "actionhandlers", type: "object", defaultValue: []});

                let uniqueProps = [];

                props.map((propItem) => {
                    let matchedPropInUniqueProps = uniqueProps.filter((uniquePropItem) => uniquePropItem.name == propItem.name);
                    if(matchedPropInUniqueProps.length == 0) {
                        uniqueProps.push(propItem);
                    }
                });

                componentPropMetadata[componentName] =  uniqueProps;

                if(!customElements.get(componentName) && WrappedComponent) {
                    window.customElements.whenDefined(componentName).then(() => {
                        remainingComponentsToRegister = remainingComponentsToRegister.filter((c) => c != componentName);
                        if(remainingComponentsToRegister.length == 0) {
                            resolve(componentPropMetadata);
                        }
                    });

                    WrappedReactComponent({
                        componentName,
                        component: withStyles(styles || "")(WrappedComponent), 
                        attributes: uniqueProps,
                        properties: uniqueProps,
                        additionalProps: [],
                        onConnected: () => {}
                    });                                      
                }else {
                    remainingComponentsToRegister = remainingComponentsToRegister.filter((c) => c != componentName);
                    if(remainingComponentsToRegister.length == 0) {
                        resolve(componentPropMetadata);
                    }
                } 
            }
        }
    })
};
