export const getPageDetails = async () => {
    let pageContents = [{
        name: "container11",
        tag: "srs-event-handler",
        props: {
            handlers: [
                {
                    eventName: "HOME#clicked",
                    type: "script",
                    script: `(function() {
                        debugger;
                        dispatchEvent("NAVIGATE_TO_PATH", {path: "/"});
                        //window.location.replace("/builder")
                    })`,
                    stopPropagation: true
                }
            ]
        },
        children: [{ 
            "name": "editor",
            "tag": "srs-codeeditor", 
            "elementid": "el123",
            "props": {
                "label": "Code Editor", 
                "value": JSON.stringify([{name: "comp1", props: []}]),
                "language": "json", 
                "height": "500px", 
                "width": "500px", 
            } 
        }]
    }];
    
    return {pageContents, pageContext: {}, pageFunctions: {}};
}