import { EventAPI } from "../commons/EventAPI";

const OPENAI_API_KEY = "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";
const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
  apiKey: OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);

const getCompletion = async (prompt) => {
  const response = await openai.createCompletion({
    model: "text-davinci-003",
    prompt: prompt,
    max_tokens: 1024,
    temperature: 0.5,
  });
  return JSON.parse(response.data?.choices?.[0]?.text);
}

let samplePage = {
  name: "container1",
  elementId: "container1",
  tag: "srs-container",
  props: {
    styles: {color: 'red'} 
  },
  children: [{
    name: "hero_container",
    tag: "srs-container",
    elementId: "hero_container",
    props: {
      styles: {color: "blue"}
    }
  }]
};

let compList = [
  "srs-container:[title, styles]", "srs-richtext:[html_string]", "srs-content-tree", "srs-builder-chrome", "srs-event-handler", "srs-modal",
  "srs-accordion:[open]", "srs-accordion-item:[title]", "srs-tab-set:[items]", "srs-tab-item:[]", "srs-alert", "srs-breadcrumb", "srs-button:[label,size(sm,md,lg)]",
  "srs-datatable", "srs-checkbox", "srs-datetime", "srs-dropdown", "srs-fileupload", "srs-heading:[content,level]", "srs-highlightedvalue", "srs-image", 
  "srs-input", "srs-labelvalue", "srs-radiobutton", "srs-select", "srs-textarea", "srs-time", "srs-toggle", "srs-typeahead", "srs-form", "srs-form-section", 
  "srs-form-field", "srs-pageheader", "srs-icon", "srs-controller", "srs-contenttree", "srs-codeeditor", "srs-popover", "srs-contentslot", "srs-json-value-picker",
  "srs-contentrenderer", "srs-uistudio"];
let contentTypes = [{ "type": "srs-container", "props": ["title", "collapse", "layout", "gap", "justifyContent", "alignItems", "direction", "wrap", "alignContent", "columns:\"1fr 1fr\"", "rows:\"1fr 1fr\"", "styles", "elementid", "elementid"] }, 
    // { "type": "srs-richtext", "props": ["html_string:escaped-html-string", "event_handlers", "elementid"] }, 
    { "type": "srs-content-tree", "props": ["message", "elementid"] }, 
    { "type": "srs-builder-chrome", "props": ["elementid"] }, { "type": "srs-event-handler", "props": ["handlers", "elementid", "elementid"] }, 
    { "type": "srs-modal", "props": ["open", "elementid"] }, { "type": "srs-accordion", "props": ["open", "elementid"] }, 
    { "type": "srs-accordion-item", "props": ["title", "elementid"] }, { "type": "srs-tab-set", "props": ["items", "elementid"] }, 
    { "type": "srs-tab-item", "props": [null, "elementid"] }, { "type": "srs-alert", "props": ["status", "icon", "prefix", "content", "link_label", "link_href", "elementid"] }, 
    { "type": "srs-breadcrumb", "props": ["items", "elementid"] }, 
    { "type": "srs-button", "props": ["label", "icononly", "icon", "icon_description", "href", "size", "kind", "tooltip_alignment", "tooltip_position", "elementid"] }, 
    { "type": "srs-datatable", "props": ["list_title", "list_subtitle", "rows", "headers", "elementid"] }, 
    { "type": "srs-checkbox", "props": ["label", "checked", "value", "elementid"] }, 
    { "type": "srs-datetime", "props": ["type", "label", "format", "placeholder", "max_date", "locale", "min_date", "disable", "show_time", "value", "elementid"] }, 
    { "type": "srs-dropdown", "props": ["items", "label", "elementid"] }, 
    { "type": "srs-fileupload", "props": ["label", "subtitle", "add_files_label", "add_files_description", "upload_button_label", "upload_endpoint", "accepted_formats", "elementid"] },
    { "type": "srs-heading", "props": ["level", "content", "elementid"] }, { "type": "srs-highlightedvalue", "props": ["value", "highlight", "elementid"] }, 
    { "type": "srs-image", "props": ["src", "alt", "width", "height", "elementid"] }, { "type": "srs-input", "props": ["label", "placeholder", "value", "elementid"] }, 
    { "type": "srs-labelvalue", "props": ["label", "value", "readonly", "placeholder", "elementid"] }, 
    { "type": "srs-radiobutton", "props": ["label", "name", "value", "checked", "elementid"] }, 
    { "type": "srs-select", "props": ["label", "options", "placeholder", "selected", "multi", "placeholder", "elementid"] }, 
    { "type": "srs-textarea", "props": ["label", "rows", "cols", "value", "disabled", "placeholder", "readonly", "elementid"] }, 
    { "type": "srs-time", "props": ["label", "size", "icon_description", "elementid"] }, { "type": "srs-toggle", "props": ["label", "value", "elementid"] }, 
    { "type": "srs-typeahead", "props": ["label", "placeholder", "selected_item", "items", "elementid"] }, { "type": "srs-form", "props": ["name", "data", "elementid"] }, 
    { "type": "srs-form-section", "props": ["open", "form_data", "title", "elementid"] }, 
    { "type": "srs-form-field", "props": ["name", "type", "width_fraction", "data", "form_data", "elementid"] }, 
    // { "type": "srs-pageheader", "props": ["header_name", "header_title", "header_navigation", "menu_items", "sidenav_items", "elementid", "elementid"] }, 
    { "type": "srs-icon", "props": ["name", "description", "size", "color", "elementid", "elementid"] }, 
    { "type": "srs-controller", "props": ["handlers", "elementid", "elementid"] }, { "type": "srs-contenttree", "props": ["items", "elementid"] }, 
    { "type": "srs-codeeditor", "props": ["label", "value", "language", "height", "width", "elementid"] }, { "type": "srs-popover", "props": ["elementid"] }, 
    { "type": "srs-contentslot", "props": ["slot_details", "elementid"] }, { "type": "srs-json-value-picker", "props": ["data", "elementid"] }, 
    { "type": "srs-contentrenderer", "props": ["elementid", "elementid"] }, { "type": "srs-uistudio", "props": ["page_id", "elementid", "elementid"] }];

export const getPageDetails = async (prompt) => {
  let pageContents = [{
    name: "topContainer",
    elementId: "topContainer",
    tag: "srs-container",
    props: {
      styles: {display: "grid", gridTemplateColumns: "4fr 1fr", marginTop: 10}
    },
    children: [
      {
        name: "input1",
        elementId: "input1",
        tag: "srs-input",
        props: {
          label: "",
          placeholder: "What do you want to see?"
        }
      }, {
        name: "applyBtn",
        elementId: "applyBtn",
        tag: "srs-button",
        props: {
          label: "Apply",
          icononly: true,
          icon: "Search"
        },
        style: {
          marginTop: 5
        }
      }],
  },
  {
    name: "bottomContainer",
    elementId: "bottomContainer",
    tag: "srs-container",
    props: {},
    children: []
  },
  {
    name: "bottomContainerController",
    tag: "srs-controller",
    elementId: "bottomContainerController",
    props: {
      handlers: [
        {
          eventName: EventAPI.BUTTON_CLICKED,
          type: "script",
          script: `(async function() {
              if(api.eventPayload?.payload?.id != "applyBtn" ) return;
              
              debugger;

              const trimmedStr = (input) => {
                return input.trim().replace(/^\s+|\s+$/g, '');
              }

              const getCompletion = async (prompt) => {
                const response = await api.helper.openai.createCompletion({
                  model: "text-davinci-003",
                  prompt: prompt,
                  max_tokens: 1024,
                  temperature: 0.5,
                });
                let result = null;
                try {
                  let trimmedstring = trimmedStr(response.data?.choices?.[0]?.text);
                  result = JSON.parse(trimmedstring);
                  if(typeof result == "string") {
                    result = JSON.parse(result);
                  }
                }catch(err) { }
                return result;
              }
              
              let input1El = api.helper.getElement("input1");
              if(input1El) {
                if(input1El.props?.value) {
                  let result = await getCompletion('Generate a ' + (input1El.props?.value) + 'in the json format similar to the sample page data ${JSON.stringify(samplePage)} strictly using the component types and their props from the list ${JSON.stringify(contentTypes)}')
                  if(result) {
                    let bottomContainerEl = api.helper.getElement("bottomContainer");
                    bottomContainerEl.children = [result];
                    api.helper.setElement("bottomContainer", bottomContainerEl);
                  }else {
                    console.log("No results returned to update the contents");
                  }
                }
              }
          })`,
          stopPropagation: true
        }
      ]
    }
  }
  ];
  return {pageContents, pageContext: {}, pageFunctions: {}};
  // return await getCompletion(`Generate a ${prompt} in the json format similar to the sample page data ${JSON.stringify(samplePage)} strictly using the component types from the list ["srs-container","srs-about","srs-home","srs-richtext","srs-content-tree","srs-builder-chrome","srs-event-handler","srs-modal","srs-accordion","srs-accordion-item","srs-tab-set","srs-tab-item","srs-alert","srs-breadcrumb","srs-button","srs-datatable","srs-checkbox","srs-datetime","srs-dropdown","srs-fileupload","srs-heading","srs-highlightedvalue","srs-image","srs-input","srs-labelvalue","srs-radiobutton","srs-select","srs-textarea","srs-time","srs-toggle","srs-typeahead","srs-form","srs-form-section","srs-form-field","srs-pageheader","srs-icon","srs-controller","srs-contenttree","srs-codeeditor","srs-popover","srs-contentslot","srs-json-value-picker","srs-contentrenderer","srs-uistudio"]`);
}