import React, { useRef, useState, useEffect } from 'react';
import PubSub from 'pubsub-js';

// Import MUI CSS from the MUI package
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { purple } from '@material-ui/core/colors';
import { muiComponentDefaultProps } from './muiComponentDefaultProps';
import { register } from './ComponentRegistry';
import { StylesProvider, jssPreset } from '@mui/styles';
import { create } from 'jss';
import Settings from "@mui/icons-material/Settings";
import Clear from "@mui/icons-material/Clear";
import Add from "@mui/icons-material/Add";
import Code from "@mui/icons-material/Code";

function wrapper(WrappedComponent, propTypes, componentType, dependentWebComponents) {
  return function RefHOC(props) {
    const ref = useRef(null);
    const componentSettings = (<div key={props.elementid} style={{ position: "absolute", backgroundColor: "var(--cds-border-interactive)", display: "flex", flexDirection: "row", height: "20px", width: "100px", zIndex: 100 }}>
      <Settings viewBox="0 0 32 32" onClick={() => {
        dispatchEvent("DESIGNTIME_SETTINGS_CLICKED", { payload: { id: props.elementid } });
      }} />
      <Clear viewBox="0 0 32 32" onClick={() => {
        dispatchEvent("DESIGNTIME_REMOVE_CLICKED", { payload: { id: props.elementid } });
      }} />
      <Add viewBox="0 0 32 32" onClick={() => {
        dispatchEvent("DESIGNTIME_ADD_CLICKED", { payload: { id: props.elementid } });
      }} />
      <Code viewBox="0 0 32 32" onClick={() => {
        dispatchEvent("DESIGNTIME_ADDBLOCK_CLICKED", { payload: { id: props.elementid } });
      }} />
    </div>);
    useEffect(() => {
      function interceptEventTarget(event) {
        if (event.composed) {
          Object.defineProperty(event, 'target', {
            value: event.composedPath()[0],
            writable: false,
            enumerable: true,
            configurable: true
          });
        }
      }

      function handleEvent(event) {
        interceptEventTarget(event);
      }

      function showComponentSettings(evt) {
        window.__SRS__ = window.__SRS__ || {};
        window.__SRS__.activeElementId = props.elementid;
        if(props.widgetid) {
          let context = {...props.context};
          context.activeElementId = props.elementid;
          dispatchEvent("WIDGET_CONTEXT_REFRESH", {widgetid: props.widgetid, payload: context});
        }else { 
          dispatchEvent("PAGE_CONTEXT_REFRESH", {});
        }
        
        // Stop the event from further propagating up the DOM tree
        evt.stopPropagation();
      }

      if (ref.current && props.designtime + "" == "true") {
        ref.current.addEventListener("mouseover", showComponentSettings);
      }

      if (ref.current && componentType == "mui") {
        // Add the event listener to the window object
        ref.current.addEventListener('mouseup', handleEvent);
        ref.current.addEventListener('mousedown', handleEvent);
        ref.current.addEventListener('touchstart', handleEvent);
        ref.current.addEventListener('touchmove', handleEvent);
        ref.current.addEventListener('touchend', handleEvent);
      }

      dispatchEvent("onload_" + props.elementid, {});
      // Remove the event listener when the component unmounts
      return () => {
        if (ref.current && props.designtime) {
          ref.current.removeEventListener("mouseover", showComponentSettings);
        }
        if (ref.current && componentType == "mui") {
          ref.current.removeEventListener('mouseup', handleEvent);
          ref.current.removeEventListener('mousedown', handleEvent);
          ref.current.removeEventListener('touchstart', handleEvent);
          ref.current.removeEventListener('touchmove', handleEvent);
          ref.current.removeEventListener('touchend', handleEvent);
        }
      };
    }, []);

    const dispatchEvent = (eventName, eventPayload) => {
      let payload = {...eventPayload};
      if(props.misc && Object.keys(props.misc).length > 0) {
        payload.misc = {...props.misc};
      }
      PubSub.publish(eventName, { detail: payload });
    }

    if (dependentWebComponents && dependentWebComponents.length > 0) {
      let webComponentsToBeRegistered = [];
      dependentWebComponents.map((dependentWebComponent, indx) => {
        if (!customElements.get(dependentWebComponent)) {
          webComponentsToBeRegistered.push(dependentWebComponent);
        }
      });
      if (webComponentsToBeRegistered && webComponentsToBeRegistered.length > 0) {
        register(webComponentsToBeRegistered, props.theme);
      }
    }

    let updatedProps = { ...props };
    Object.keys(updatedProps).map((propName) => {
      if (propName) {
        let propType = "string";
        if (propTypes && propTypes.length > 0) {
          propTypes.map((propItem) => {
            if (propItem.name == propName) {
              propType = propItem.type;
            }
          });
        }
      }
    });

    let inlineStyles = {
      "--cds-label-01-font-size": "0.9rem",
      "--cds-label-01-font-weight": 800
    }

    if(props.inlinestyles) {
      inlineStyles = {...inlineStyles, ...props.inlinestyles};
    }

    let result = null;

    if (props.hasOwnProperty("visible") && props.visible + "" == "false") {
      return result;
    }

    let componentDetails = null;
    // if (props.designtime + "" == "true") {
    // // if(window.__SRS__?.activeElementId == updatedProps.elementid) {
    //   if(updatedProps.activenode) {
    //     componentDetails = componentSettings;
    //     inlineStyles.pointerEvents = "none";
    //   }else {
    //     inlineStyles.pointerEvents = "all";
    //   }
    // }

    if(props.designtime + "" == "true" && props.context?.activeElementId == updatedProps.elementid) {
      componentDetails = componentSettings;
      inlineStyles.pointerEvents = "none";
    }else {
      inlineStyles.pointerEvents = "all";
    }

    let actionHandlerEl = null;
    let actionHandlers = typeof props.actionhandlers == "string"? JSON.parse(props.actionhandlers): props.actionhandlers;
    if(actionHandlers && actionHandlers.length > 0) {
      actionHandlerEl = (<srs-controller 
        ref={el => {
          if (!el) return;
          el.context = props.context;
          el.clan = props.clan;
          el.widgetid = props.widgetid; 
          el.designtime = props.designtime;
          el.elementid = props.elementid + "_ctrl"; 
          el.handlers = actionHandlers;
        }}/>);
    }
    
    if (componentType) {
      switch (componentType) {
        case "mui":
          inlineStyles["display"] = "flex";
          inlineStyles["flexDirection"] = "column";
          let muiTheme = "dark";
          if (props.theme == "white" || props.theme == "g10") {
            muiTheme = "light"
          } else if (props.theme == "g90" || props.theme == "g100") {
            muiTheme = "dark";
          }

          let emotionRoot = props.shadowRoot.querySelector("#emotionRoot");
          let componentRoot = props.shadowRoot.querySelector("#shadowRootEl");;
          let shadowRootElement = null;

          emotionRoot && emotionRoot.remove();
          componentRoot && componentRoot.remove();

          emotionRoot = document.createElement("style");
          emotionRoot.setAttribute("id", "emotionRoot");
          shadowRootElement = document.createElement("div");
          shadowRootElement.setAttribute("id", "shadowRootEl");
          shadowRootElement.style.position = "absolute";
          props.shadowRoot.appendChild(emotionRoot);
          componentRoot = props.shadowRoot.appendChild(shadowRootElement);

          const cache = createCache({
            key: "css",
            prepend: true,
            container: emotionRoot
          });

          let wrapperComponentName = WrappedComponent.displayName || WrappedComponent.name;
          wrapperComponentName = wrapperComponentName && wrapperComponentName.startsWith("Srs") ? wrapperComponentName.substring(3) : null;
          let themeComponents = muiComponentDefaultProps(shadowRootElement);

          const shadowTheme = createTheme({
            components: themeComponents,
            palette: {
              mode: muiTheme,
              type: muiTheme,
              primary: {
                main: purple[500],
              },
            },
          });

          const jss = create({
            ...jssPreset(),
            insertionPoint: componentRoot,
          });

          result = (
            <StylesProvider jss={jss}>
              <CacheProvider value={cache}>
                <ThemeProvider theme={shadowTheme}>
                  {componentDetails}
                  {actionHandlerEl}
                  <div ref={ref} style={inlineStyles}>
                    <WrappedComponent
                      {...updatedProps}
                      key={props.elementid}
                      dispatchEvent={dispatchEvent}
                      setValue={props.setValue || ((val) => { })}
                      muiTheme={shadowTheme}
                      ref={ref}>
                    </WrappedComponent>
                  </div>
                </ThemeProvider>
              </CacheProvider>
            </StylesProvider>
          );
          break;
      }
    } else {
      let carbonStylesUrl = "./carbon-components.css";
      if(window.__SRS__?.mode == "sandbox") {
        carbonStylesUrl = "https://know.srste.in/carbon-components.css";
      }
      result = (
        <>
          <style>
            @import "https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Condensed|IBM+Plex+Sans:400,600&display=swap";
            {`@import "${carbonStylesUrl}";`}
          </style>
          {componentDetails}
          {actionHandlerEl}
          <div ref={ref} style={inlineStyles}>
            <WrappedComponent key={props.elementid}
              {...updatedProps}
              dispatchEvent={dispatchEvent}
              setValue={props.setValue || ((val) => { })}
              ref={ref}>
            </WrappedComponent>
          </div>
        </>
      );
    }

    return result;
  };
}

export default wrapper;