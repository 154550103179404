export const getPageDetails = () => {
    let pageContents = [{
        name: "uistudio",
        tag: "srs-uistudio",
        props: {
            page_id: ""
        }
    }];

    return {pageContents, pageContext: {}, pageFunctions: {}};
}