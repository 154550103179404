export const getPageDetails = () => {
    let pageContents = [{
        name: "jsonEditor1",
        elementId: "jsonEditor1",
        tag: "srs-jsoneditor",
        props: {
            schema: {
                "type": "object",
                "title": "Table Schema",
                "properties": {
                    "name": {
                        "type": "string",
                        "title": "Model Name"
                    },
                    "tableName": {
                        "type": "string",
                        "title": "Table Name"
                    },
                    "schema": {
                        "type": "array",
                        "title": "Columns",
                        "items": {
                            "type": "object",
                            "properties": {
                                "name": {
                                    "type": "string",
                                    "title": "Name"
                                },
                                "type": {
                                    "type": "string",
                                    "title": "Type",
                                    "enum": [
                                        "increments",
                                        "string",
                                        "timestamp"
                                    ]
                                },
                                "properties": {
                                    "type": "object",
                                    "title": "Properties",
                                    "properties": {
                                        "unsigned": {
                                            "type": "boolean",
                                            "title": "Unsigned"
                                        },
                                        "notNullable": {
                                            "type": "boolean",
                                            "title": "Not Nullable"
                                        },
                                        "primary": {
                                            "type": "boolean",
                                            "title": "Primary Key"
                                        },
                                        "defaultTo": {
                                            "type": "string",
                                            "title": "Default Value"
                                        }
                                    },
                                    "required": [
                                        "notNullable"
                                    ],
                                    "dependencies": {
                                        "type": [
                                            "increments"
                                        ],
                                        "properties": {
                                            "unsigned": {
                                                "type": "boolean",
                                                "title": "Unsigned"
                                            },
                                            "notNullable": {
                                                "type": "boolean",
                                                "title": "Not Nullable",
                                                "default": true
                                            },
                                            "primary": {
                                                "type": "boolean",
                                                "title": "Primary Key",
                                                "default": true
                                            },
                                            "defaultTo": {
                                                "type": "string",
                                                "title": "Default Value"
                                            }
                                        },
                                        "required": [
                                            "notNullable",
                                            "primary"
                                        ]
                                    }
                                }
                            },
                            "required": [
                                "name",
                                "type",
                                "properties"
                            ]
                        }
                    },
                    "relations": {
                        "type": "array",
                        "title": "relations",
                        "items": {
                            "type": "object",
                            "properties": {
                                "type": {
                                    "type": "string",
                                    "title": "Type",
                                    "enum": [
                                        "hasOne",
                                        "hasMany"
                                    ]
                                },
                                "name": {
                                    "type": "string",
                                    "title": "Name"
                                },
                                "model": {
                                    "type": "string",
                                    "title": "Model"
                                },
                                "foreignKey": {
                                    "type": "string",
                                    "title": "Foreign Key"
                                }
                            },
                            "required": [
                                "name",
                                "type",
                                "model",
                                "foreignKey"
                            ]
                        }
                    }
                },
                "required": [
                    "schema"
                ]
            }
        }
    }, {
        name: "input1",
        elementId: "input1",
        tag: "srs-input"
    }, {
        name: "codeeditor1",
        elementId: "codeeditor1",
        tag: "srs-codeeditor",
        props: {
            label: "Code Editor",
            value: "Test1"
        }
    }, {
        name: "srs-datetime1",
        elementId: "datetime1",
        tag: "srs-datetime",
        props: {
            type: "single",
            format: "d-m-Y",
            placeholder: "dd/mm/yyyy",
            max_date: "",
            min_date: "",
            disable: "false",
            show_time: "false",
            value: "01/01/1982"
        }
    }, {
        name: "checkbox1",
        elementId: "checkbox1",
        tag: "srs-checkbox",
        props: {
            label: "Is Checked",
            checked: true
        }
    }, {
        name: "button1",
        tag: "srs-button",
        props: {
            label: "Submit",
            icon: "Add",
            icononly: false,
            icon_description: "This is to submit the form",
            href: "#",
            size: "sm"
        }
    }, {
        name: "jsonpicker1",
        tag: "srs-json-value-picker",
        props: {
            data: {
                key1: "Value 1",
                key2: {
                    key21: {
                        key211: "key211 value",
                        key212: "key212 value"
                    },
                    key22: {
                        key221: "key221 value",
                        key222: "key222 value",
                        key223: {
                            key2231: "key2131 value",
                            key2231: "key2131 value"
                        }
                    }
                },
                key3: "Value 3"
            }
        }
    }, {
        name: "breadcrumb1",
        tag: "srs-breadcrumb",
        props: {
            items: [{ name: "Item1", url: "#" }, { name: "Item2", url: "#" }]
        }
    }, {
        name: "alert1",
        tag: "srs-alert",
        props: {
            content: "This is a message",
            status: "error",
            prefix: "Info:"
        }
    }, {
        name: "container1",
        tag: "srs-event-handler",
        props: {
            handlers: [
                {
                    eventName: "HOME#clicked",
                    type: "script",
                    script: `(function() {
                        debugger;
                        dispatchEvent("NAVIGATE_TO_PATH", {path: "/builder"});
                        //window.location.replace("/builder")
                    })`,
                    stopPropagation: true
                }
            ]
        },
        children: [{
            name: "container2",
            tag: "srs-container",
            props: {},
            children: [{
                name: "builder1",
                tag: "srs-home",
                props: {
                    message: "Go to builder, click here"
                }
            }]
        }]
    }, {
        name: "container2",
        tag: "srs-event-handler",
        props: {
            handlers: [
                {
                    eventName: "HOME#clicked",
                    type: "script",
                    script: `(function() {
                        console.log("This is the script code", context);
                        debugger;
                        let updatedClan = clan? [...clan]: [];
                        updatedClan[0].children[0].props.message = "THis is new message";
                        updatedClan.push({name: "e3", tag: "my-home", props: {message: "This is the new block created at " + Date.now()}});
                        console.log("clan is ", updatedClan);
                        return {context: {"a": "a1"}, clan: updatedClan};
                    })`,
                    stopPropagation: true
                }
            ]
        },
        children: [{
            name: "home2",
            tag: "srs-home",
            props: {
                message: "Update Context"
            }
        }]
    }, {
        name: "modal1",
        tag: "srs-modal",
        props: {
            open: false
        },
        children: [{
            name: "modal-content1",
            tag: "srs-home",
            props: {
                message: "Trigger Button",
                slotname: "trigger"
            },
        }, {
            name: "modal-content1",
            tag: "srs-home",
            props: {
                message: "This is the content slot element",
                slotname: "content"
            },
        }]
    }, {
        name: "accordion1",
        tag: "srs-accordion",
        props: {
        },
        children: [{
            name: "accordiom-item1",
            tag: "srs-accordion-item",
            props: {
                title: "Item 1"
            },
            children: [{
                name: "content1",
                tag: "srs-home",
                props: {
                    message: "This is the content slot element1",
                }
            }, {
                name: "content2",
                tag: "srs-home",
                props: {
                    message: "This is the content slot element1"
                }
            }]
        }, {
            name: "accordiom-item2",
            tag: "srs-accordion-item",
            props: {
                title: "Item 2"
            },
            children: [{
                name: "content1",
                tag: "srs-home",
                props: {
                    message: "This is the content slot element1",
                }
            }, {
                name: "content2",
                tag: "srs-home",
                props: {
                    message: "This is the content slot element1"
                }
            }]
        }]
    }, {
        name: "tab1",
        tag: "srs-tab-set",
        props: {
            items: [{
                title: "Item1",
                slotname: "item1"
            }, {
                title: "Item2",
                slotname: "item2"
            }]
        },
        children: [{
            name: "tab-item1",
            tag: "srs-tab-item",
            props: {
                slotname: "item1"
            },
            children: [{
                name: "content1",
                tag: "srs-home",
                props: {
                    message: "This is the content slot element11",
                }
            }, {
                name: "content2",
                tag: "srs-home",
                props: {
                    message: "This is the content slot element12"
                }
            }]
        }, {
            name: "tab-item2",
            tag: "srs-tab-item",
            props: {
                slotname: "item2"
            },
            children: [{
                name: "content1",
                tag: "srs-home",
                props: {
                    message: "This is the content slot element21",
                }
            }, {
                name: "content2",
                tag: "srs-home",
                props: {
                    message: "This is the content slot element22"
                }
            }]
        }]
    }];

    return { pageContents, pageContext: {}, pageFunctions: {} };
}