import React from 'react';
import ReactDOM from 'react-dom/client';
import { EventAPI } from './EventAPI';
import { debounce, isEqual } from 'lodash';

const WrappedReactComponent = (props) => {
    const ReactComponent = props.component;
    if (!window.customElements) return;

    class ReactComponentWrapper extends HTMLElement {

        constructor() {
            super();
            this._properties = {};
            
            for (const propIndx in props.properties) {
                Object.defineProperty(this, props.properties[propIndx].name, {
                    get() {
                        return this._properties[props.properties[propIndx].name]
                    },
                    set(value) {
                        if (value !== this._properties[props.properties[propIndx].name]) {
                            // Update the property value
                            this._properties[props.properties[propIndx].name] = value;
                            // Call the render method to update the component's rendering
                            this.renderReactComponent();
                        }
                    }
                });  
                this._properties[props.properties[propIndx].name] = props.properties[propIndx].defaultValue;
            }

            const mountPoint = document.createElement('span');
            this.attachShadow({ mode: 'open' }).appendChild(mountPoint);
            this._root = null;
        }

        renderReactComponent = debounce(() => {
            if(this._root) {
                this._root.render(<ReactComponent
                    theme={this['theme']}
                    setValue={this.setValue.bind(this)} 
                    shadowRoot={this.shadowRoot}
                    {...this._properties}/>);
            }
        }, 100)

        connectedCallback() {
            if (!this._root) {
                const mountPoint = this.shadowRoot.querySelector('span');
                this._root = ReactDOM.createRoot(mountPoint);
            }

            const attributesToSubscribe = props.attributes || [];
            attributesToSubscribe.forEach((attribute) => {
                const defaultValue =
                    (attribute.defaultValue !== undefined &&
                        attribute.defaultValue !== null) ? attribute.defaultValue : "";

                const value = this.getAttribute(attribute.name) || defaultValue;
                let parsedValue = value;
                if (attribute.type == "object") {
                    if (typeof value == "string") {
                        try {
                            parsedValue = JSON.parse(value);
                        } catch (err) {
                            // console.error(`Error occurred while parsing the attribute value of ${attribute.name}`);
                        }
                    }
                } else if (attribute.type === 'number') {
                    parsedValue = parseFloat(value);
                } else if (attribute.type === 'boolean') {
                    parsedValue = value === 'true';
                }

                this.compAttributes = {
                    ...this.compAttributes,
                    [attribute.name]: parsedValue,
                }
            });


            const propertiesToSubscribe = props.properties || [];
            propertiesToSubscribe.forEach((property) => {
                const defaultValue =
                    (property.defaultValue !== undefined &&
                        property.defaultValue !== null) ? property.defaultValue : "";

                const value = this[property.name] || this.getAttribute(property.name) || defaultValue;
                let parsedValue = value;
                if (property.type == "functions") {
                    if (typeof value == "string") {
                        try {
                            parsedValue = JSON.parse(value);
                        } catch (err) {
                            // console.error(`Error occurred while parsing the attribute value of ${property.name}`);
                        }
                    } else {
                        parsedValue = value;
                    }
                } else if (property.type == "object") {
                    if (typeof value == "string") {
                        try {
                            parsedValue = JSON.parse(value);
                        } catch (err) {
                            // console.error(`Error occurred while parsing the attribute value of ${property.name}`);
                        }
                    } else {
                        parsedValue = value;
                    }
                } else if (property.type === 'number') {
                    parsedValue = parseFloat(value);
                } else if (property.type === 'boolean') {
                    parsedValue = value === 'true';
                }

                this.compProperties = {
                    ...this.compProperties,
                    [property.name]: parsedValue,
                }
            });

            if (props.onConnected) {
                props.onConnected();
            }

            this.renderReactComponent();
            // this._syncAttributesWithProperties();
        }

        _syncAttributesWithProperties() {
            const attributesToSubscribe = props.attributes || [];
            attributesToSubscribe.forEach((attribute) => {
                if (attribute.name != "context" && attribute.name != "clan" && attribute.name != "theme") {
                    if (this.compAttributes[attribute.name] !== this.compProperties[attribute.name]) {
                        let attributeValue = this.compAttributes[attribute.name];
                        if (attribute.type === 'number') {
                            attributeValue = attributeValue + "";
                        } else if (attribute.type === 'boolean') {
                            attributeValue = attributeValue + "";
                        }
                        // this.setAttribute(attribute.name, attributeValue);
                        this.compAttributes = {
                            ...this.compAttributes,
                            [attribute.name]: attributeValue
                        }
                        this.compProperties = {
                            ...this.compProperties,
                            [attribute.name]: attributeValue
                        };
                    }
                }
            });
        }

        attributeChangedCallback(name, oldValue, newValue) {
            if (oldValue == newValue) return;

            const attributesToSubscribe = props.attributes || [];
            const attribute = attributesToSubscribe.find(
                (attribute) => attribute.name === name
            );
            let parsedAttributeValue = newValue;
            if (attribute.type === 'object') {
                if (typeof newValue == "string") {
                    try {
                        parsedAttributeValue = JSON.parse(newValue);
                    } catch (err) {
                        // console.error(`Error occurred while parsing the attribute value of ${name}`);
                    }
                }
            } else if (attribute.type === 'number') {
                parsedAttributeValue = parseFloat(newValue);
            } else if (attribute.type === 'boolean') {
                parsedAttributeValue = newValue === 'true';
            }

            this.compAttributes = {
                ...this.compAttributes,
                [name]: parsedAttributeValue,
            };

            const propertiesToSubscribe = props.properties || [];
            const property = propertiesToSubscribe.find(
                (property) => property.name === name
            );
            let parsedPropertyValue = newValue;
            if (property.type === 'object') {
                if (typeof newValue == "string") {
                    try {
                        parsedPropertyValue = JSON.parse(newValue);
                    } catch (err) {
                        // console.error(`Error occurred while parsing the attribute value of ${name}`);
                    }
                }
            } else if (property.type === 'number') {
                parsedPropertyValue = parseFloat(newValue);
            } else if (property.type === 'boolean') {
                parsedPropertyValue = newValue === 'true';
            }

            this.compProperties = {
                ...this.compProperties,
                [name]: parsedPropertyValue,
            };
            this["_" + name] = parsedPropertyValue; 

            if (!this._root) {
                const mountPoint = this.shadowRoot.querySelector('span');
                this._root = ReactDOM.createRoot(mountPoint);
            }
            this.renderReactComponent();
        }

        static get observedAttributes() {
            let observedAttributes = [];
            for (const propIndx in props.properties) {
                observedAttributes.push(props.properties[propIndx].name);
            }
            return Array.from(observedAttributes);
        }

        setValue(val) {
            if (this._properties["value"]) {
                if (this._properties["value"] != val) {
                    const event = new CustomEvent(EventAPI.SET_CONTENT_BLOCK_VALUE, { detail: { elementid: this.getProperties().elementid, value: val, clan: this.getProperties().clan }, bubbles: true, cancellable: true, composed: true });
                    this.dispatchEvent(event);
                    // this.setAttribute("value", val);
                }
            }
        }

        getProperties() {
            return { ...this._properties }
        }
    }

    window.customElements.define(
        props.componentName,
        ReactComponentWrapper
    );
};

export { WrappedReactComponent };
