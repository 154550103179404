import { getPageDetails as builderDetails} from "../pageContents/Builder";
import { getPageDetails as formDetails} from "../pageContents/Form";
import { getPageDetails as homeDetails} from "../pageContents/Home";
import { getPageDetails as listDetails} from "../pageContents/List";
import { getPageDetails as uistudioDetails} from "../pageContents/UIStudio";
import { getPageDetails as dynamicPageDetails } from "../pageContents/DynamicPage";
import { getPageDetails as monacoEditorDetails } from "../pageContents/MonacoEditor";
import { getPageDetails as inlineEditorDetails } from "../pageContents/InlineUIEditor";
import { getPageDetails as modelBuilderDetails } from "../pageContents/ModelBuilder";
import { getPageDetails as pagePreview } from "../pageContents/PagePreview";
import { DEFAULT_THEME } from "./Constants";
import { EventAPI } from "./EventAPI";
import {http} from "../components/HttpUtil";
import { debounce } from 'lodash';

export const resolvePage = function (pathName) {
    let pageId = "home";
    switch (pathName) {
        case "/":
            pageId = "home";
            break;
        case "/modelbuilder": 
            pageId = "modelbuilder";
            break;
        case "/builder":
            pageId = "builder";
            break;
        case "/form":
            pageId = "form";
            break;
        case "/list":
            pageId = "list";
            break;
        case "/uistudio": 
            pageId = "uistudio";
            break;
        case "/dynamic": 
            pageId = "dynamic";
            break;
        case "/editor": 
            pageId = "editor";
            break;
        case "/uieditor": 
            pageId = "uieditor";
            break;
        case "/pagepreview": 
            pageId = "pagepreview";
            break;
        default:
            pageId = pathName.split("/")[1];
            break;
    }

    return pageId;
}

export const getPageFunctions = async function(pageId) {
    let pageFunctions = {};
    if(pageId) {
        switch(pageId) {
            case "uieditor": 
                pageFunctions = {
                    "getHelloWorld": function(api) {    
                        return "Hello world!";
                    },
                    "getContents": function(api) {
                        return api.context.previewData || {contents: []};
                    }
                };
                break;
        }
    }
    return pageFunctions;
}

export const getPageContext = async function(pageId) {
    let pageContext = {};
    if(pageId) {
        switch(pageId) {
            case "uieditor": 
                pageContext = {
                
                };
                break;
        }
    }
    return pageContext;
}

const fetchPageData = async (pageId) => {
    let matchingResults = null;
    try {
        matchingResults = await http.post("/api/collection/entity/read", {
            collection: "pages",
            matches: {name: pageId}
        });
        return matchingResults;
    }catch(err) {
        matchingResults = null;
        return matchingResults;
    }
}
const debouncedFetchPageData = debounce(fetchPageData, 50);

export const getPageData = async function (pageId) {
    let allContents = [
        {
            name: "header-container",
            tag: "srs-container",
            props: {
                styles: {
                    display: "flex",
                    flexDirection: "column"
                }
            },
            children: [
                // {
                //     name: "sessionvalidator",
                //     tag: "srs-sessionvalidator",
                //     elementId: "sessionvalidator",
                //     props: {
                //         cookie_name: "session-touched",
                //         alert_when: 60000
                //     }
                // },
                {
                    name: "header",
                    tag: "srs-pageheader",
                    props: {
                        header_name: "Srste name",
                        header_title: "Srste [Platform]",
                        menu_items: [{
                            name: "Home",
                            link: "/home"
                        }, {
                            name: "Form",
                            link: "/form"
                        }, {
                            name: "List",
                            link: "/list"
                        }, {
                            name: "Builder",
                            link: "/builder"
                        }, {
                            name: "UI Studio",
                            link: "/uistudio"
                        }, {
                            name: "Dynamic Contents",
                            link: "/dynamic"
                        }, {
                            name: "Code Editor",
                            link: "/editor"
                        }, {
                            name: "UI Editor",
                            link: "/uieditor"
                        }, {
                            name: "Model Builder",
                            link: "/modelbuilder"
                        }, {
                            name: "Page Preview",
                            link: "/pagepreview"
                        }],
                        sidenav_items: [{
                            name: "home",
                            iconName: "home",
                            label: "Home",
                            iconType: "fontawesome",
                            color: "rgb(237, 158, 0)",
                            size: 20,
                            children: [{
                                name: "Db2Database",
                                iconName: "Db2Database",
                                label: "Data Management",
                                iconType: "carbon",
                                color: "rgb(197 153 0)",
                                size: 20,
                                menuItems: [{
                                    name: "Studio",
                                    label: "Studio",
                                    children: [{
                                        name: "Home",
                                        label: "Home",
                                        link: "/home"
                                    }, {
                                        name: "Form",
                                        label: "Form",
                                        link: "/form"
                                    }]
                                }]
                            }, {
                                name: "user",
                                iconName: "user",
                                label: "User Experience",
                                iconType: "fontawesome",
                                color: "rgb(24 211 168)",
                                size: 20,
                                menuItems: [{
                                    name: "Home",
                                    label: "Home",
                                    link: "/home"
                                }, {
                                    name: "Form",
                                    label: "Form",
                                    link: "/form"
                                }]
                            }, {
                                name: "user-lock",
                                iconName: "user-lock",
                                label: "Security",
                                iconType: "fontawesome",
                                color: "rgb(133 215 24)",
                                size: 20
                            }, {
                                name: "code",
                                iconName: "code",
                                label: "Scripting",
                                iconType: "fontawesome",
                                color: "rgb(29 227 191)",
                                size: 20
                            }, {
                                name: "shapes",
                                iconName: "shapes",
                                label: "Builders",
                                iconType: "fontawesome",
                                color: "rgb(229 49 156)",
                                size: 20
                            }, {
                                name: "robot",
                                iconName: "robot",
                                label: "Conversational Models",
                                iconType: "fontawesome",
                                color: "rgb(22, 255, 212)",
                                size: 20
                            }, {
                                name: "network-wired",
                                iconName: "network-wired",
                                label: "Integrations",
                                iconType: "fontawesome",
                                color: "rgb(231 100 38)",
                                size: 20
                            }, {
                                name: "route",
                                iconName: "route",
                                label: "Navigation",
                                iconType: "fontawesome",
                                color: "#2cb1dd",
                                size: 20
                            }, {
                                name: "cogs",
                                iconName: "cogs",
                                label: "Administration",
                                iconType: "fontawesome",
                                color: "rgb(24 245 89)",
                                size: 20
                            }
                            ]
                        }
                        ]
                    },
                    children: [
                        {
                            name: "search-handler",
                            tag: "srs-event-handler",
                            elementId: "search-event",
                            props: {
                                slotname: "search",
                                handlers: [
                                    {
                                        eventName: EventAPI.ICON_CLICKED,
                                        type: "script",
                                        script: `(function() {
                                            debugger;
                                            dispatchEvent("${EventAPI.HEADER_SEARCH_CLICKED}");
                                        })`,
                                        stopPropagation: true
                                    }
                                ]
                            },
                            children: [
                                {
                                    name: "search-node",
                                    tag: "srs-icon",
                                    props: {
                                        name: "Search",
                                        size: 24
                                    }
                                }
                            ]
                        }, {
                            name: "notification-handler",
                            tag: "srs-event-handler",
                            elementId: "notification-event",
                            props: {
                                slotname: "notifications",
                                handlers: [
                                    {
                                        eventName: EventAPI.ICON_CLICKED,
                                        type: "script",
                                        script: `(function() {
                                            debugger;
                                            dispatchEvent("${EventAPI.HEADER_NAVIGATION_CLICKED}");
                                        })`,
                                        stopPropagation: true
                                    }
                                ]
                            },
                            children: [
                                {
                                    name: "notifications-node",
                                    tag: "srs-icon",
                                    props: {
                                        name: "NotificationFilled",
                                        size: 24
                                    }
                                }
                            ]
                        }, {
                            name: "appswitcher-handler",
                            tag: "srs-event-handler",
                            elementId: "appswitcher-event",
                            props: {
                                slotname: "appswitcher",
                                handlers: [
                                    {
                                        eventName: EventAPI.ICON_CLICKED,
                                        type: "script",
                                        script: `(function() {
                                            debugger;
                                            dispatchEvent("${EventAPI.HEADER_APPSWITCHER_CLICKED}");
                                        })`,
                                        stopPropagation: true
                                    }
                                ]
                            },
                            children: [
                                {
                                    name: "appswitcher-node",
                                    tag: "srs-icon",
                                    props: {
                                        name: "Switcher",
                                        size: 24
                                    }
                                }
                            ]
                        }, {
                            name: "settings-handler",
                            tag: "srs-event-handler",
                            elementId: "settings-event",
                            props: {
                                slotname: "settings",
                                handlers: [
                                    {
                                        eventName: EventAPI.ICON_CLICKED,
                                        type: "script",
                                        script: `(function() {
                                            debugger;
                                            dispatchEvent("${EventAPI.HEADER_UX_SETTINGS_CLICKED}");
                                        })`,
                                        stopPropagation: true
                                    }
                                ]
                            },
                            children: [
                                {
                                    name: "settings-node",
                                    tag: "srs-icon",
                                    props: {
                                        name: "GuiManagement",
                                        size: 24
                                    }
                                }
                            ]
                        }]
                }
            ]
        },
        {
            name: "body-container",
            tag: "srs-container",
            props: {
                styles: {
                    marginTop: "3rem",
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 65
                }
            },
            children: []
        },
        {
            name: "page_controller1",
            tag: "srs-controller",
            props: {
                handlers: [
                    {
                        name: "headerSearchClicked",
                        eventName: EventAPI.INPUT_CHANGED,
                        type: "script",
                        script: `(function() {
                            debugger;
                            dispatchEvent("${EventAPI.HEADER_SEARCH_CLICKED}");
                        })`,
                        stopPropagation: true
                    }
                ]
            }
        }];
    let pageContents = [];
    let pageContext = {};
    let pageFunctions = {};
    let pageDetails = null;
    let matchingResults = null;

    matchingResults = debouncedFetchPageData(pageId);
    
    if(matchingResults?.data?.result?.length > 0) {
        pageContents = matchingResults?.data?.result?.[0]?.pageContents || [];
        pageContext = matchingResults?.data?.result?.[0]?.pageContext || {};
        pageFunctions = matchingResults?.data?.result?.[0]?.pageFunctions || {};
    }else {
        switch (pageId) {
            case "home":
                pageDetails = homeDetails();
                break;
            case "builder":
                pageDetails = builderDetails();
                break;
            case "form":
                pageDetails = formDetails();
                break;
            case "list":
                pageDetails = listDetails();
                break;
            case "uistudio":
                pageDetails = uistudioDetails();
                break;
            case "dynamic":
                pageDetails = await dynamicPageDetails("service portal page having banner and few action cards below it along with some help and guidance section");
                break;
            case "editor":
                pageDetails = await monacoEditorDetails();
                break;
            case "uieditor":
                pageDetails = await inlineEditorDetails();
                break;
            case "modelbuilder":
                pageDetails = await modelBuilderDetails();
                break;
            case "pagepreview":
                pageDetails = await pagePreview();
                break;
            default:
                pageContents = [{
                    name: "chrome",
                    tag: "srs-builder-chrome"
                }, {
                    name: "tree",
                    tag: "srs-content-tree"
                }, {
                    name: "container4",
                    tag: "srs-event-handler",
                    props: {
                        handlers: [
                            {
                                eventName: "HOME#clicked",
                                type: "script",
                                script: `(function() {
                                    window.location.replace("/")
                                })`,
                                stopPropagation: true
                            }
                        ]
                    },
                    children: [{
                        name: "builder-home",
                        tag: "my-home",
                        props: {
                            message: "Go to home, click here"
                        }
                    }]
                }, {
                    name: "ele-2",
                    tag: "srs-home",
                    props: {
                        message: "Dummy Card outside"
                    }
                }, {
                    name: "container21",
                    tag: "srs-container",
                    props: {
                    },
                    children: [{
                        name: "home21",
                        tag: "srs-home",
                        props: {
                            message: "Hello Chaitanya, welcome back!"
                        }
                    }]
                }];
                pageContext = {};
                pageFunctions = {};
                pageDetails = {pageContents, pageContext, pageFunctions};
                break;
        }
        pageContents = pageDetails.pageContents;
        pageContext = pageDetails.pageContext;
        pageFunctions = pageDetails.pageFunctions;
    }

    allContents[1].children = Array.isArray(pageContents)? pageContents: [pageContents];
    return {pageContents: allContents, pageContext, pageFunctions};
}

export const getPageTheme = function (pageId) {
    let pageTheme = "";
    switch (pageId) {
        case "home":
        case "builder":
            pageTheme = DEFAULT_THEME;
            break;
        default:
            break;
    }
    return pageTheme;
}