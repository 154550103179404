// src/App.js
import React, { useEffect, useState, useRef, createContext, useContext } from "react";
import { EventAPI } from "./commons/EventAPI";
import { getPageData, resolvePage, getPageTheme, getPageContext, getPageFunctions } from "./commons/PageUtils";
import { register } from "./commons/ComponentRegistry";
import Login from "./Login";
import useToken from './useToken';
import cookie from "js-cookie";
import process from "process";

import { GlobalTheme } from '@carbon/react';
import {
  StyledEngineProvider,
  createTheme,
  ThemeProvider
} from "@mui/material/styles";
import { orange } from '@mui/material/colors';
import PubSub from 'pubsub-js';
import { debounce } from 'lodash';

const App = () => {
  const [contents, setContents] = useState(window?.__SRS__?.contents || []);
  const [pageContext, setPageContext] = useState(window?.__SRS__?.context || { showHeader: false, headerSideNav: false });
  const [pageTheme, setPageTheme] = useState(window.__SRS__?.theme || "g90");
  const [readyToRender, setReadyToRender] = useState(false);
  const { token, setToken } = useToken();
  const [ts, setTs] = useState(Date.now() + "");
  const [isSandbox, setIsSandbox] = useState(window.__SRS__?.mode == "sandbox");
  const [isDevServer, setIsDevServer] = useState(!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
  const [functions, setFunctions] = useState({});

  const updatePageContents = (contents) => {
    window.__SRS__ = window.__SRS__ || {};
    window.__SRS__.contents = contents;
    setContents(contents);
  }

  const updatePageContext = (_context) => {
    window.__SRS__ = window.__SRS__ || {};
    window.__SRS__.context = window.__SRS__.context || {};
    window.__SRS__.context = {...window.__SRS__.context, ..._context};
    setPageContext({...pageContext, ...window.__SRS__.context});
  }

  const updatePageFunctions = (functions) => {
    window.__SRS__ = window.__SRS__ || {};
    window.__SRS__.functions = window.__SRS__.functions || {};
    window.__SRS__.functions = {...window.__SRS__.functions, ...functions};
    let functionMap = {};
    Object.keys(window.__SRS__.functions).map((functionName) => {
      // functionMap["$widget_cr_1_" + functionName] = "";
      functionMap[functionName] = "";
    });
    setFunctions(functionMap);
  }

  // create element ref
  const innerRef = useRef(null);

  let eventBinding = false;

  const handleSetElementValue = (elementId, elementValue, contentItems) => {
    const updatedContents = [...contentItems];
    updateElementValue(elementId, elementValue, updatedContents);
    updatePageContents(updatedContents);
  }

  const updateElementValue = (key, newValue, nestedContents) => {
    nestedContents.forEach((element, index) => {
      if (element.elementId === key) {
        nestedContents[index].props = nestedContents[index].props || {};
        nestedContents[index].props.value = newValue;
      } else if (element.children) {
        updateElementValue(key, newValue, element.children);
      }
    });
  }

  const pageContentsRefresh = (e) => {
    let payload = e.detail.payload;
    if (payload) {
      updatePageContents(payload);
    }
  }

  const pageContextRefresh = (e) => {
    let payload = e.detail.payload;
    if (payload) {
      updatePageContext(payload);
    }
    PubSub.publish("GLOBAL_CONTENTS_UPDATED", {});
  }

  const navigateToPath = async (e) => {
    let payload = e.detail;
    if (payload) {
      let pageId = resolvePage(payload.path);
      let pageData = await getPageData(pageId);
      let pageContents = pageData.pageContents;
      let contextForPage = pageData.pageContext;
      let pageFunctions = pageData.pageFunctions;
      updatePageContext(contextForPage);
      updatePageFunctions(pageFunctions);
      updatePageContents(pageContents);
    }
  }

  const pageThemeChanged = (e) => {
    let theme = e.detail.theme;
    if (theme) {
      setPageTheme(theme);
    }
  }

  const headerSideNavExpanded = (e) => {
    let value = e.detail.expanded;
    pageContext.headerSideNav = value;
    updatePageContext(pageContext);
  }

  const headerAdded = (e) => {
    pageContext.showHeader = true;
    updatePageContext(pageContext);
  }

  const publishGlobalContents = (evt) => {
    PubSub.publish("GLOBAL_CONTENTS_UPDATED", { detail: evt.detail });
  }

  const setContentBlockValue = ((e) => {
    let elementid = e.detail.elementid;
    let elementValue = e.detail.value;
    let clan = e.detail.clan;
    handleSetElementValue(elementid, elementValue, clan);
  }).bind(this);

  const handlePageDetails = () => {
    updatePageContents(window.__SRS__.contents);
    updatePageContext(window.__SRS__.context);
  };

  useEffect(() => {

    let tokens = [];

    if(readyToRender) {
      const debouncedPageContentsRefresh = debounce(pageContentsRefresh, 50);
      const debouncedPageContextRefresh = debounce(pageContextRefresh, 50);
      const debouncedNavigateToPath = debounce(navigateToPath, 50);
      const debouncedPageThemeChanged = debounce(pageThemeChanged, 50);
      const debouncedHeaderSideNavExpanded = debounce(headerSideNavExpanded, 50);
      const debouncedHeaderAdded = debounce(headerAdded, 50);
      const debouncedSetContentBlockValue = debounce(setContentBlockValue, 50);
      const debouncedHandlePageDetails = debounce(handlePageDetails, 50);
      
      const debounceInithHandlers = debounce(() => {
        window.__SRSINIT__ = window.__SRSINIT__ || {};
        window.__SRSINIT__.tokens = window.__SRSINIT__.tokens || [];
        window.__SRSINIT__.tokens.map(token => PubSub.unsubscribe(token));
        window.__SRSINIT__.tokens = [];
        tokens = window.__SRSINIT__.tokens;
        tokens.push(PubSub.subscribe("PAGE_CONTENTS_REFRESH", (async (eventName, eventData) => { debouncedPageContentsRefresh(eventData) })))
        tokens.push(PubSub.subscribe("PAGE_CONTEXT_REFRESH", (async (eventName, eventData) => { debouncedPageContextRefresh(eventData) })));
        PubSub.subscribe("NAVIGATE_TO_PATH", (async (eventName, eventData) => { debouncedNavigateToPath(eventData) }));
        PubSub.subscribe("PAGE_THEME_CHANGE", (async (eventName, eventData) => { debouncedPageThemeChanged(eventData) }));
        PubSub.subscribe(EventAPI.HEADER_SIDENAV_EXPANDED, (async (eventName, eventData) => { debouncedHeaderSideNavExpanded(eventData) }));
        PubSub.subscribe(EventAPI.HEADER_ADDED, (async (eventName, eventData) => { debouncedHeaderAdded(eventData) }));
        PubSub.subscribe(EventAPI.SET_CONTENT_BLOCK_VALUE, (async (eventName, eventData) => { debouncedSetContentBlockValue(eventData) }));
        PubSub.subscribe("GLOBAL_CONTENTS_UPDATED", (async (eventName, eventData) => { debouncedHandlePageDetails(eventData) }));
      }, 10);
      
      debounceInithHandlers();
      window.__SRSINIT__ = window.__SRSINIT__ || {};
      if(!window.__SRSINIT__.hasOwnProperty("GLOBAL_CONTENTS_UPDATED")) {
        window.__SRSINIT__["GLOBAL_CONTENTS_UPDATED"] = window.addEventListener("GLOBAL_CONTENTS_UPDATED", publishGlobalContents);
      }
    }
    return () => {
      tokens.map(token => PubSub.unsubscribe(token));
      if(window.__SRSINIT__.hasOwnProperty("GLOBAL_CONTENTS_UPDATED")) {
        window.removeEventListener("GLOBAL_CONTENTS_UPDATED", publishGlobalContents);
        delete window.__SRSINIT__["GLOBAL_CONTENTS_UPDATED"];
      }
    }
  }, [readyToRender]);

  useEffect(() => {
    if (!eventBinding) {
      register(["srs-widget", "srs-contentrenderer", "srs-controller"], pageTheme)
        .then((d) => {
          setReadyToRender(true);
        });
      
      eventBinding = true;
    }
  }, []);

  useEffect(() => {
    if(window.__SRS__) {
      setContents(window.__SRS__.contents);
      setPageContext(window.__SRS__.context);
    }
    window.__SRSINIT__ = window.__SRSINIT__ || {}; 
    window.__SRSINIT__.startingPoint = window.location.pathname;
    if(window.location.search) {
      window.__SRSINIT__.startingPoint += "?" + window.location.search;
    }
    if(window.location.hash) {
      window.__SRSINIT__.startingPoint += "#" + window.location.hash;
    }
    const pageId = !isSandbox? resolvePage(window.location.pathname): "";
    if(pageId) {
      getPageData(pageId).then((pageData) => {
        updatePageFunctions(pageData?.pageFunctions || {});
        updatePageContext(pageData?.pageContext || {});
        updatePageContents(pageData?.pageContents || []);                  
      });
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const handleSessionExpired = (e) => {
      setToken(null);
    }

    if (innerRef.current) {
      innerRef.current.addEventListener(EventAPI.SESSION_EXPIRED, handleSessionExpired)
    }
    return () => {
      if(innerRef.current) {
        innerRef.current.removeEventListener(EventAPI.SESSION_EXPIRED, handleSessionExpired);
      }
    }
  }, [token]);

  const popperTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: orange[500],
      },
    },
  });

  let contentRendererEl = (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={popperTheme}>
          <GlobalTheme theme={pageTheme}>
            <srs-widget ref={(el) => {
              if (!el) return;
              el.widgetcontents = contents;
              el.widgetcontext = pageContext;
              el.widgetfunctions = functions;
              el.widgetstyles = { minHeight: "100vh", maxHeight: "100vh"};
              // el.clan = contents;
              // el.context = pageContext;
              el.elementid = "cr_1";
              el.name = "cr_1";
              el.theme = pageTheme;
            }} data-carbon-theme={pageTheme} />
          </GlobalTheme>
        </ThemeProvider>
      </StyledEngineProvider>);
      
  let loginPage = null;

  if (!isSandbox && !isDevServer && (!token || !cookie.get("session-touched"))) {
    loginPage = (
      <GlobalTheme theme={pageTheme}>
        <Login setToken={setToken} />
      </GlobalTheme>
    );
  }

  return (<div ref={innerRef}>
    {
      loginPage || (readyToRender ? contentRendererEl : <div>initialising...</div>)
    }
  </div>);
};

export default App;
