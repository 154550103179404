export const getPageDetails = () => {
    let pageContents = [
        {
        name: "form1",
        tag: "srs-form",
        props: {
            data: {
                field1: {
                    value: "xyz"
                },
                field2: {
                    value: "lmn"
                },
                field3: {
                    value: true
                },
                field4: {
                    value: true
                }
            }
        },
        children: [
            {
                name: "section0",
                tag: "srs-form-section",
                props: {
                    name: "section0",
                    title: "Section 0",
                    open: true
                },
                children: [{
                    name: "srs-card",
                    elementId: "srs-card",
                    tag: "srs-card"
                }, {
                    name: "srs-multiselectadd",
                    elementId: "srs-multiselectadd",
                    tag: "srs-multiselectadd"
                }, {
                    name: "srs-mui-accordion",
                    elementId: "srs-mui-accordion",
                    tag: "srs-mui-accordion"
                }]
            },
            {
                name: "section1",
                tag: "srs-form-section",
                props: {
                    name: "section1",
                    title: "Section 1",
                    open: true
                },
                children: [{
                    name: "field1",
                    tag: "srs-form-field",
                    props: {
                        type: "input",
                        name: "field1",
                        width_fraction: 0.5,
                        data: {
                            label: "Field 1",
                            placeholder: "Enter Field1 Value"
                        }
                    }
                }, {
                    name: "field2",
                    tag: "srs-form-field",
                    props: {
                        type: "input",
                        name: "field2",
                        width_fraction: 0.5,
                        data: {
                            label: "Field 2",
                            placeholder: "Enter Field2 Value"
                        }
                    }
                }, {
                    name: "field3",
                    tag: "srs-form-field",
                    elementId: "field3",
                    props: {
                        type: "checkbox",
                        name: "field3",
                        width_fraction: 0.5,
                        data: {
                            label: "Field 3",
                            placeholder: "Enter Field2 Value"
                        }
                    }
                }, {
                    name: "field4",
                    tag: "srs-form-field",
                    props: {
                        type: "toggle",
                        name: "field4",
                        width_fraction: 0.5,
                        data: {
                            label: "Field 4"
                        }
                    }
                }, {
                    name: "field5",
                    elementId: "field5",
                    tag: "srs-form-field",
                    props: {
                        type: "datetime",
                        name: "field5",
                        width_fraction: 0.5,
                        data: {
                            type: "single",
                            label: "Datetime Label",
                            format: "d-m-Y",
                            placeholder: "dd/mm/yyyy",
                            max_date: "",
                            min_date: "",
                            disable: "false",
                            show_time: "false",
                            value: "01/01/1982"
                        }
                    }
                }, {
                    name: "field6",
                    elementId: "field6",
                    tag: "srs-form-field",
                    props: {
                        type: "textarea",
                        name: "field6",
                        width_fraction: 0.5,
                        data: {
                            label: "TextArea Label",
                            // rows: "3",
                            // cols: "100"
                        }
                    }
                }]
            }, {
                name: "section2",
                tag: "srs-form-section",
                props: {
                    name: "section2",
                    title: "Section 2"
                },
                children: [{
                    name: "field1",
                    tag: "srs-form-field",
                    props: {
                        type: "fileupload",
                        name: "field11",
                        width_fraction: 0.5,
                        data: {
                            label: "Field 11",
                            placeholder: "Enter Field11 Value"
                        }
                    }
                }, {
                    name: "field21",
                    tag: "srs-form-field",
                    props: {
                        type: "select",
                        name: "field21",
                        width_fraction: 0.5,
                        data: {
                            label: "MultiSelect Label",
                            placeholder: "Choose an option",
                            multi: true,
                            selected: ["item2", "item3"],
                            // options: [{group: true, label: "Group 1", options: [{label: "Item1", value: "item1"}, {label: "Item2", value: "item2"}]},
                            //             {group: true, label: "Group 2", options: [{label: "Item3", value: "item3"}, {label: "Item4", value: "item4"}]}],
                            options: [{ label: "Item1", value: "item1" }, { label: "Item2", value: "item2" }, { label: "Item3", value: "item3" }]
                        }
                    }
                }, {
                    name: "field31",
                    tag: "srs-form-field",
                    elementId: "field31",
                    props: {
                        type: "codeeditor",
                        name: "field31",
                        width_fraction: 0.5,
                        data: {
                            label: "Field 31",
                            value: "Enter Field31 Value"
                        }
                    }
                }, {
                    name: "field41",
                    tag: "srs-contenttree",
                    props: {
                        type: "contenttree",
                        name: "field41",
                        width_fraction: 0.5,
                        data: {
                            label: "Field 41",
                            items: [{ name: "Node1", label: "Node 1", children: [{ name: "Node11", label: "Node 11" }] }, { name: "Node2", label: "Node 2", children: [{ name: "Node21", label: "Node 21" }] }]
                        }
                    }
                }]
            }]
    }];
    return {pageContents, pageContext: {}, pageFunctions: {}};
}