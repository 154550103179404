import React from 'react';

const withStyles = (styles) => WrappedComponent => {
  return class WithStyles extends React.Component {
    render() {
      const styleElement = React.createElement('style', {
        dangerouslySetInnerHTML: { __html: styles }
      });

      return (
        <div>
          {styleElement}
          <WrappedComponent {...this.props} />
        </div>
      );
    }
  };
};

export default withStyles;
