import React, { useState, useRef, useEffect } from "react";
import wrapper from "../../../commons/WebComponentWrapper";
import { TextInput  } from "carbon-components-react";
import { EventAPI } from "../../../commons/EventAPI";

const dispatchedEvents = [EventAPI.INPUT_CHANGED];

/*
    Example: <srs-input label="Label" placeholder="Enter a value" value=""/>
*/
const SrsInput = function (props, ref) {
    const [inputValue, setInputValue] = useState(props.value || "");
    useEffect(() => {
        setInputValue(props.value || "");
        props.setValue(props.value || "")
    }, [props]);

    let inlineStyles = {
        "--cds-label-01-font-size": "0.9rem",
        "--cds-label-01-font-weight": 800,
    };
    let TextInputComponent = props.type == "password"? TextInput.PasswordInput: TextInput;
    return (
        <div style={inlineStyles}>
            <TextInputComponent
                labelText={props.label}
                placeholder={props.placeholder}
                value={inputValue}
                onBlur={(evt) => {
                    if(props.onChange) {
                        props.onChange(evt.target?.value || "");     
                    }
                }}
                onChange={(evt) => {
                    setInputValue(evt.target?.value || "");
                    props.setValue(evt.target?.value || "");
                    props.dispatchEvent(EventAPI.INPUT_CHANGED, { payload: {value: evt.target?.value || "", id: props.elementid} });
                }}
            />
        </div>
      );
}

const styles = `
    .cds--text-input {
        border: 1px solid lightgray !important;
        border-radius: 5px;
    }

    .cds--text-input:focus, .cds--text-input:active {
        outline: 0px !important;
        border: 2px solid !important;
        border-radius: 5px;
    } 
`
const props = [{
    name: "label",
    defaultValue: "Input",
    type: "string"
}, {
    name: "placeholder",
    defaultValue: "Enter a value",
    type: "string"
}, {
    name: "value",
    defaultValue: "",
    type: "string"
}, {
    name: "type",
    defaultValue: "",
    type: "string"
}]

const slots = [];

const WrappedComponent = wrapper(React.forwardRef(SrsInput), props);
export { WrappedComponent, styles, props, dispatchedEvents, slots };