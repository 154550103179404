export const muiComponentDefaultProps = function (shadowRootElement) {
    return {
        MuiModal: {
            defaultProps: {
                container: shadowRootElement
            }
        },
        MuiPopper: {
            defaultProps: {
                container: shadowRootElement
            }
        },
        MuiPopover: {
            defaultProps: {
                container: shadowRootElement
            }
        },    
        MuiSlider: {
            defaultProps: {
                container: shadowRootElement
            }
        }
    }
}