export const EventAPI = {
    BUTTON_CLICKED: "SrsButton#clicked",
    CHECKBOX_CHANGED: "SrsCheckbox#changed",
    SORTABLECONTENTTREE_UPDATED: "SrsSortableContentTree#updated",
    SORTABLECONTENTTREE_EDITNODE: "SrsSortableContentTree#editnode",
    DATETIME_CHANGED: "SrsDatetime#changed",
    DROPDOWN_CHANGED: "SrsDropdown#changed",
    INPUT_CHANGED: "SrsInput#changed",
    RADIO_CHECKED: "SrsRadio#checked",
    SELECT_CHANGED: "SrsSelect#changed",
    JSONEDITOR_CHANGED: "JSONEditor#changed",
    MUI_BUTTON_CLICKED: "SrsMuiButton#clicked",
    MUI_SELECT_CHANGED: "SrsMuiSelect#changed",
    MODAL_CLOSED: "MuiModal#closed",
    POPOVER_CLOSED: "MuiPopover#closed",
    POPOVER_OPENED: "MuiPopover#opened",
    DIALOG_CLOSED: "MuiDialog#closed",
    TEXTAREA_CHANGED: "SrsTextArea#changed",
    CONTENTTREE_NODE_CLICKED: "SrsContentTree#clicked",
    MUI_TEXTFIELD_CHANGED: "MuiTextField#changed",
    MUI_ACCORDION_EXPANDED: "MuiAccordion#expanded",
    TIME_CHANGED: "SrsTime#changed",
    TOGGLE_CHANGED: "SrsToggle#changed",
    EDITORCODE_CHANGED: "SrsCodeEditor#changed",
    TYPEAHEAD_CHANGED: "SrsTypeAhead#changed",
    STEPPER_SUBMITTED: "SrsStepper#submitted",
    STEPPER_STEPCHANGED: "SrsStepper#stepchanged",
    ICONBUTTON_CLICKED: "IconButton#clicked",
    PILL_CLICKED: "SrsPill#clicked",
    FORM_FIELD_VALUE_CHANGED: "SrsFormField#changed",
    FORM_VALUE_CHANGED: "SrsForm#changed",
    FILE_UPLOADED: "SrsFileUpload#uploaded",
    FILE_UPLOADING: "SrsFileUpload#uploading",
    FILE_UPLOAD_FAILED: "SrsFileUpload#uploadFailed",
    CARD_CLICKED: "SrsCard#clicked",
    ICON_CLICKED: "SrsIcon#clicked",
    HEADER_SEARCH_CLICKED: "SrsHeader#searchClicked",
    HEADER_NAVIGATION_CLICKED: "SrsHeader#navigationClicked",
    HEADER_APPSWITCHER_CLICKED: "SrsHeader#appSwitcherClicked",
    HEADER_UX_SETTINGS_CLICKED: "SrsHeader#uxSettingsClicked",
    HEADER_SIDENAV_EXPANDED: "SrsHeader#sideNavExpanded",
    HEADER_ADDED: "SrsHeader#headerAdded",
    PAGE_THEME_CHANGE: "PAGE_THEME_CHANGE",
    DATATABLE_ROW_CLICKED: "SrsDataTable#rowclicked",
    DATATABLE_SORT_REQUESTED: "SrsDataTable#sort",
    SET_CONTENT_BLOCK_VALUE: "SET_CONTENT_BLOCK_VALUE",
    SESSION_EXPIRED: "SESSION_EXPIRED",
    STYLES_UPDATED: "STYLES_UPDATED"
}