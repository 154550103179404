import React, { useState } from 'react';
import { WrappedComponent as SrsInput } from "./components/basic/form/Input";
import { Button, InlineNotification } from "carbon-components-react";
import  "./Login.css";

async function loginUser(credentials) {
  return new Promise((resolve, reject) => {
    let url =  (process.env.NODE_ENV === 'development')? "http://localhost:5000/login": "/login";
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Referrer-Policy': 'no-referrer'
      },
      body: JSON.stringify(credentials)
    })
    .then(data => resolve(data.json()))
    .catch((err) => resolve({error: err.message}));
  });
  // return {token: "Hello123"};
}

async function resetPwd(credentials) {
  return new Promise((resolve, reject) => {
    let url =  (process.env.NODE_ENV === 'development')? "http://localhost:5000/resetpassword": "/resetpassword";
    fetch('/resetpassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Referrer-Policy': 'no-referrer'
      },
      body: JSON.stringify(credentials)
    })
    .then(data => resolve(data.json()))
    .catch((err) => resolve({error: err.message}));
  });
  // return {token: "Hello123"};
}

export default function Login({ setToken }) {
  const [username, setUserName] = useState("");
  const [currentpassword, setCurrentPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [mode, setMode] = useState("login");
  const [toast, setToast] = useState({});

  const handleSubmit = async e => {
    e.preventDefault();
    setToast({});
    const loginToken = await loginUser({
      username,
      password
    });
    if(loginToken && loginToken.token) {
      setToken(loginToken);
      window.location.href = window.__SRSINIT__.startingPoint || "/home";
    }else {
      setToast({message: "Invalid credentials!", kind: "error"})
    }
  }

  const changeToResetMode = () => setMode("reset");
  const changeToLoginMode = () => setMode("login");

  const handleResetPassword = async e => {
    e.preventDefault();
    if(newpassword != confirmpassword) {
      setToast({message: "Password didn't match, try again!", kind: "error"});
    }else {
      const resetPwdResult = await resetPwd({
        username,
        password: newpassword
      });
      if(resetPwdResult && resetPwdResult.id) {
        setToast({message: "Password resetted successfully!", kind: "info"});
        setMode("login");
      }else {
        setToast({message: "Reset failed, try again!", kind: "error"});
      }
    }
  }
  let loginEl = null;
  loginEl = mode == "login"? (<div className="login-page">
  <div className="login-wrapper">
    {toast && toast.message? <InlineNotification kind={toast.kind} title={toast.message}/>: null}
    <h1 className='login-title'>User Login</h1>
    <div style={{ padding: 5, border: "1px solid #bebec2"}}>
      <SrsInput label="User Name" placeholder="Enter User Name..." value={username} onChange={val => setUserName(val)} />
      <SrsInput label="Password" type="password" placeholder="Enter password..." value={password} onChange={val => setPassword(val)} />
      <div style={{display: "flex", marginTop: 20, justifyContent: "space-around"}}>
        <Button size="sm" onClick={handleSubmit}>Submit</Button>
      </div>
    </div>
    <div class="resetpwd">
      <a onClick={changeToResetMode}>Reset password</a>
    </div>
  </div>
</div>): (<div className="reset-password-page">
  <div className="login-wrapper">
    {toast && toast.message? <InlineNotification kind={toast.kind} title={toast.message}/>: null}
    <h1 className='login-title'>Reset Password</h1>
    <div style={{ padding: 5, border: "1px solid #bebec2"}}>
      <SrsInput label="User Name" placeholder="Enter User Name..." value={username} onChange={val => setUserName(val)} /> 
      <SrsInput label="Current Password" type="password" placeholder="Enter current password..." value={currentpassword} onChange={val => setCurrentPassword(val)} />
      <SrsInput label="New Password" type="password" placeholder="Enter new password..." value={newpassword} onChange={val => setNewPassword(val)} />
      <SrsInput label="Confirm Password" type="password" placeholder="Confirm password..." value={confirmpassword} onChange={val => setConfirmPassword(val)} />
      <div style={{display: "flex", marginTop: 20, justifyContent: "space-around"}}>
        <Button size="sm" onClick={handleResetPassword}>Reset Password</Button>
      </div>
    </div>
    <div class="resetpwd">
      <a onClick={changeToLoginMode}>Back to login</a>
    </div>
  </div>
</div>);
  return loginEl;
}