import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import DummyApp from './DummyApp';
import reportWebVitals from './reportWebVitals';
import { register } from "./commons/ComponentRegistry";

window.__SRSROOT__ = window.__SRSROOT__ || "#__srsteroot__";
var srsRootElement = document.querySelector(window.__SRSROOT__);
if(srsRootElement) {
  srsRootElement.innerHTML = "";
  const root = ReactDOM.createRoot(srsRootElement);
  let buildMode = process.env.BUILD_MODE || "";
  buildMode = buildMode.trim();
  root.render(
    <>
      <App/>
      {buildMode == "true"? <DummyApp/>: null}
    </>
  );
}

window.__SRSINIT__ = window.__SRSINIT__ || {};
if(!window.__SRSINIT__.hasOwnProperty("mountListener")) {
  window.__SRSINIT__.mountListener = window.addEventListener('SRSTE_MOUNT', function(event) {
    // Extract the root element selector from the event payload
    var rootSelector = event.detail.rootSelector;
    var pageData = event.detail.pageData;
    var mountElement = event.detail.mountElement;
    
    // Find the root element using the selector
    var rootElement = mountElement || document.querySelector(rootSelector);
    
    // Only proceed if the root element exists
    if (rootElement) {
      if(pageData) {
        //Not using the global context so no need to erase the old instances of SRSTE contents
        register(["srs-contentrenderer"], window.__SRS__?.theme || "g90")
          .then((d) => {
            var contentRendererEl = document.createElement('srs-contentrenderer');
            contentRendererEl.overrides = JSON.stringify({
              contents: pageData.contents || [],
              context: pageData.context || {},
              functions: pageData.functions,
              designtime: false
            });
        
            // Append the content renderer into the root element
            rootElement.appendChild(contentRendererEl);
          });
      }else {
        if(window.__SRSROOT__) {
          var previousRootElement = document.querySelector(window.__SRSROOT__);
          if(previousRootElement) {
            ReactDOM.createRoot(previousRootElement).render(null);
          }
        }
        const rootEl = ReactDOM.createRoot(rootElement);
        rootEl.render(
          <App/>
        );
        window.__SRSROOT__ = rootSelector;
      }
    }
  });
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//{buildMode == "true"? <DummyApp/>: <App/>}
reportWebVitals();
