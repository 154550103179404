export const getPageDetails = async () => {
    let modelSchema = {
        "type": "object",
        "title": "Table Schema",
        "properties": {
            "name": {
                "type": "string",
                "title": "Model Name"
            },
            "scope": {
                "type": "string",
                "title": "Scope Name"
            },
            "tableName": {
                "type": "string",
                "title": "Table Name"
            },
            "schema": {
                "type": "array",
                "title": "Columns",
                "items": {
                    "type": "object",
                    "properties": {
                        "name": {
                            "type": "string",
                            "title": "Name"
                        },
                        "type": {
                            "type": "string",
                            "title": "Type",
                            "enum": [
                                "increments",
                                "string",
                                "integer",
                                "float",
                                "boolean",
                                "datetime",
                                "timestamp"
                            ]
                        },
                        "properties": {
                            "type": "object",
                            "title": "Properties",
                            "properties": {
                                "unsigned": {
                                    "type": "boolean",
                                    "title": "Unsigned"
                                },
                                "notNullable": {
                                    "type": "boolean",
                                    "title": "Not Nullable"
                                },
                                "primary": {
                                    "type": "boolean",
                                    "title": "Primary Key"
                                },
                                "defaultTo": {
                                    "type": "string",
                                    "title": "Default Value"
                                }
                            },
                            "required": [
                                "notNullable"
                            ],
                            "dependencies": {
                                "type": [
                                    "increments"
                                ],
                                "properties": {
                                    "unsigned": {
                                        "type": "boolean",
                                        "title": "Unsigned"
                                    },
                                    "notNullable": {
                                        "type": "boolean",
                                        "title": "Not Nullable",
                                        "default": true
                                    },
                                    "primary": {
                                        "type": "boolean",
                                        "title": "Primary Key",
                                        "default": true
                                    },
                                    "defaultTo": {
                                        "type": "string",
                                        "title": "Default Value"
                                    }
                                },
                                "required": [
                                    "notNullable",
                                    "primary"
                                ]
                            }
                        }
                    },
                    "required": [
                        "name",
                        "type",
                        "properties"
                    ]
                }
            },
            "relations": {
                "type": "array",
                "title": "relations",
                "items": {
                    "type": "object",
                    "properties": {
                        "type": {
                            "type": "string",
                            "title": "Type",
                            "enum": [
                                "belongsTo",
                                "belongsToMany",
                                "hasOne",
                                "hasMany"
                            ]
                        },
                        "name": {
                            "type": "string",
                            "title": "Name"
                        },
                        "model": {
                            "type": "string",
                            "title": "Model"
                        },
                        "foreignKey": {
                            "type": "string",
                            "title": "Foreign Key"
                        }
                    },
                    "required": [
                        "name",
                        "type",
                        "model",
                        "foreignKey"
                    ]
                }
            }
        },
        "required": [
            "schema",
            "name",
            "tableName"
        ]
    };

    let jsonEditorChanged = `(async function() {
        let elementId = api.eventPayload?.payload?.id;   
        let jsonEditorValue = api.eventPayload?.payload?.value; 
        let ctx = api.context || {}; 
        ctx.jsonEditorValue = jsonEditorValue;
        return {context: ctx};
    })`;

    let onSaveBtnClicked = `(async function() {
        let elementId = api.eventPayload?.payload?.id;   
        let jsonEditorValue = api.eventPayload?.payload?.value; 
        let ctx = api.context || {}; 
        console.log(ctx.jsonEditorValue);
        console.log(api.helper.http.request);
        let response = await api.helper.http.request({
            method: "post",
            url: "/user/12345",
            data: {
                firstName: 'Fred',
                lastName: 'Flintstone'
            }
        }); 
        return {context: ctx};
    })`;

    let pageContents = [
        {
            "name": "TopContainer",
            "tag": "srs-container",
            "props": {
                "styles": {
                    display: "flex",
                    gap: "0px",
                    alignItems: "stretch",
                    flexDirection: "row",
                    justifyContent: "flex-end"
                },
                "embedded_styles": ""
            },
            "children": [
                {
                    name: "saveBtn",
                    tag: "srs-button",
                    props: {
                        label: "Save",
                        icon: "Save",
                        icononly: false,
                        href: "#",
                        size: "sm",
                        actionhandlers: [
                            {
                                "name": "onSaveBtnClicked",
                                "eventName": "SrsButton#clicked",
                                "type": "script",
                                "script": onSaveBtnClicked,
                                "stopPropagation": true
                            }
                        ]
                    }
                }
            ]
        },
        {
            "name": "ContentSection",
            "tag": "mui-grid",
            "props": {
                "container": true,
                "sx": {
                    "width": "100%"
                }
            },
            "children": [
                {
                    "name": "LeftContainer",
                    "tag": "mui-grid",
                    "props": {
                        "item": true,
                        "md": 6,
                        "sx": {
                            "width": "100%"
                        }
                    },
                    "children": [
                        {
                            name: "jsonEditor1",
                            elementId: "jsonEditor1",
                            tag: "srs-jsoneditor",
                            props: {
                                schema: modelSchema,
                                actionhandlers: [
                                    {
                                        "name": "modelChanged",
                                        "eventName": "JSONEditor#changed",
                                        "type": "script",
                                        "script": jsonEditorChanged,
                                        "stopPropagation": true
                                    }
                                ]
                            }
                        }
                    ]
                },
                {
                    "name": "rightContainer",
                    "tag": "mui-grid",
                    "props": {
                        "item": true,
                        "md": 6,
                        "sx": {
                            "width": "100%"
                        }
                    },
                    "children": [
                        {
                            "name": "jsonData",
                            "tag": "srs-codeeditor",
                            "props": {
                                "label": "",
                                "language": "json",
                                "height": "90vh",
                                "width": "47vw"
                            },
                            "overrides": {
                                "value": "[[api.context.jsonEditorValue]]"
                            }
                        }
                    ]
                }
            ]
        }
    ];

    return { pageContents, pageContext: { }, pageFunctions: {} };
}