export const getPageDetails = () => {
    let pageContents = [
        {
        name: "container11",
        tag: "srs-event-handler",
        props: {
            handlers: [
                {
                    eventName: "HOME#clicked",
                    type: "script",
                    script: `(function() {
                        debugger;
                        dispatchEvent("NAVIGATE_TO_PATH", {path: "/"});
                        //window.location.replace("/builder")
                    })`,
                    stopPropagation: true
                }
            ]
        },
        children: [{
            name: "container21",
            tag: "srs-container",
            props: {},
            children: [{
                name: "builder1",
                tag: "srs-home",
                props: {
                    message: "Go to builder, click here1"
                }
            }]
        }]
    }, {
        name: "container22",
        tag: "srs-event-handler",
        props: {
            handlers: [
                {
                    eventName: "HOME#clicked",
                    type: "script",
                    script: `(function() {
                        console.log("This is the script code", context);
                        debugger;
                        let updatedClan = clan? [...clan]: [];
                        updatedClan[0].children[0].props.message = "THis is new message";
                        updatedClan.push({name: "e3", tag: "my-home", props: {message: "This is the new block created at " + Date.now()}});
                        console.log("clan is ", updatedClan);
                        return {context: {"a": "a1"}, clan: updatedClan};
                    })`,
                    stopPropagation: true
                }
            ]
        },
        children: [{
            name: "home23",
            tag: "srs-home",
            props: {
                message: "Hello Chaitanya, welcome back1!"
            }
        }]
    }, {
        name: "home31",
        tag: "srs-home",
        props: {
            message: "Hello Chaitanya, welcome back1!"
        },
        children: [{
            name: "home4",
            tag: "srs-home",
            props: {
                message: "Hello Chaitanya, welcome back1!"
            },
        }]
    }];
    return {pageContents, pageContext: {}, pageFunctions: {}};
}
