export const componentLookup = {
    "srs-container": {
        component: "basic/Container.js",
        label: "Container",
        order: 1
    },
    "srs-about": {
        component: "About.js",
        label: "About",
        order: 100
    },
    "srs-home": {
        component: "Home.js",
        label: "Home",
        order: 101
    },
    "srs-jsxconverter": {
        component: "builder/JSXConverter.js",
        label: "JSXConverter",
        order: 300
    },
    "srs-stylecontainer": {
        component: "builder/StyleContainer.js",
        label: "StyleContainer",
        order: 310
    },
    "srs-pagepreview": {
        component: "builder/PagePreview.js",
        label: "PagePreview",
        order: 311
    },
    "srs-richtext": {
        component: "RichText.js",
        label: "RichText",
        order: 2
    },
    "srs-content-tree": {
        component: "ContentTree.js",
        label: "ContentTree",
        order: 3
    },
    "srs-widget": {
        component: "Widget.js",
        label: "Widget",
        order: 3
    },
    "srs-sortable-content-tree": {
        component: "basic/SortableContentTree.js",
        label: "Sortable Content tree",
        order: 3
    },
    "srs-builder-chrome": {
        component: "builder/Chrome.js",
        label: "Chrome",
        order: 102
    },
    "srs-builder-componentsettings": {
        component: "builder/ComponentSettings.js",
        label: "Component Settings",
        order: 102
    },
    "srs-event-handler": {
        component: "ComponentEventHandler.js",
        label: "Event Handler",
        order: 4
    },
    "srs-modal": {
        component: "basic/Modal.js",
        label: "Modal",
        order: 5
    },
    "srs-accordion": {
        component: "basic/Accordion.js",
        label: "Accordion",
        order: 6
    },
    "srs-accordion-item": {
        component: "basic/AccordionItem.js",
        label: "AccordionItem",
        order: 7
    },
    "srs-tab-set": {
        component: "basic/TabSet.js",
        label: "TabSet",
        order: 8
    },
    "srs-tab-item": {
        component: "basic/TabSetItem.js",
        label: "TabSetItem",
        order: 9
    },
    "srs-alert": {
        component: "basic/Alert.js",
        label: "Alert",
        order: 10
    },
    "srs-breadcrumb": {
        component: "basic/Breadcrumb.js",
        label: "Breadcrumb",
        order: 11
    },
    "srs-button": {
        component: "basic/Button.js",
        label: "Button",
        order: 12
    },
    'srs-datatable': {
        component: "basic/DataTable.js",
        label: "DataTable",
        order: 13
    },
    "srs-checkbox": {
        component: "basic/form/Checkbox.js",
        label: "Checkbox",
        order: 14
    },
    "srs-datetime": {
        component: "basic/form/DateTime.js",
        label: "DateTime",
        order: 15
    },
    "srs-dropdown": {
        component: "basic/form/DropDown.js",
        label: "DropDown",
        order: 16
    },
    "srs-fileupload": {
        component: "basic/form/FileUpload.js",
        label: "FileUpload",
        order: 17
    },
    "srs-heading": {
        component: "basic/form/Heading.js",
        label: "Heading",
        order: 18
    },
    "srs-highlightedvalue": {
        component: "basic/form/HighlightedValue.js",
        label: "HighlightedValue",
        order: 19
    },
    "srs-image": {
        component: "basic/form/Image.js",
        label: "Image",
        order: 20
    },
    "srs-input": {
        component: "basic/form/Input.js",
        label: "Input",
        order: 21
    },
    "srs-labelvalue": {
        component: "basic/form/LabelValue.js",
        label: "LabelValue",
        order: 22
    },
    "srs-radiobutton": {
        component: "basic/form/RadioButton.js",
        label: "RadioButton",
        order: 23
    },
    "srs-select": {
        component: "basic/form/Select.js",
        label: "Select",
        order: 24
    },
    "srs-textarea": {
        component: "basic/form/TextArea.js",
        label: "TextArea",
        order: 25
    },
    "srs-time": {
        component: "basic/form/Time.js",
        label: "Time",
        order: 26
    },
    "srs-toggle": {
        component: "basic/form/Toggle.js",
        label: "Toggle",
        order: 27
    },
    "srs-typeahead": {
        component: "basic/form/TypeAhead.js",
        label: "TypeAhead",
        order: 28
    },
    "srs-form": {
        component: "basic/Form.js",
        label: "Form",
        order: 29
    },
    "srs-form-section": {
        component: "basic/FormSection.js",
        label: "FormSection",
        order: 30
    },
    "srs-form-field": {
        component: "basic/FormField.js",
        label: "FormField",
        order: 31
    },
    "srs-pageheader": {
        component: "basic/Header.js",
        label: "Header",
        order: 32
    },
    "srs-icon": {
        component: "basic/Icon.js",
        label: "Icon",
        order: 33
    },
    "srs-controller": {
        component: "basic/Controller.js",
        label: "Controller",
        order: 34
    },
    "srs-relay": {
        component: "basic/Relay.js",
        label: "Relay",
        order: 34
    },
    "srs-contenttree": {
        component: "basic/ContentTree.js",
        label: "ContentTree",
        order: 35
    },
    "srs-codeeditor": {
        component: "basic/CodeEditor.js",
        label: "CodeEditor",
        order: 36
    },
    "srs-code-block": {
        component: "basic/CodeBlock.js",
        label: "CodeBlock",
        order: 36
    },
    "srs-popover": {
        component: "basic/Popover.js",
        label: "Popover",
        order: 37
    },
    "srs-contentslot": {
        component: "basic/ContentSlot.js",
        label: "ContentSlot",
        order: 38
    },
    "srs-jsoneditor": {
        component: "basic/JSONEditor.js",
        label: "JSON Editor"
    },
    "srs-json-value-picker": {
        component: "basic/JSONValuePicker.js",
        label: "JSON ValuePicker"
    },
    "srs-contentrenderer": {
        component: "ContentRenderer.js",
        label: "Content Renderer"
    },
    "srs-uistudio": {
        component: "ui-studio/UIStudio.js",
        label: "UIStudio",
        order: 39
    },
    "srs-sessionvalidator": {
        component: "basic/SessionValidator.js",
        label: "Session Validator",
        order: 40
    },
    "srs-multiselectadd": {
        component: "mui/patterns/MultiSelectAdd.js",
        label: "MultiSelect Add",
        order: 43
    },
    "mui-accordion": {
        component: "mui/basic/Accordion.js",
        label: "Accordion",
        order: 43
    },
    "mui-alert": {
        component: "mui/basic/Alert.js",
        label: "Alert",
        order: 43
    },
    "mui-alert-title": {
        component: "mui/basic/AlertTitle.js",
        label: "AlertTitle",
        order: 43
    },
    "mui-appbar": {
        component: "mui/basic/AppBar.js",
        label: "AppBar",
        order: 43
    },
    "mui-auto-complete": {
        component: "mui/basic/AutoComplete.js",
        label: "AutoComplete",
        order: 43
    },
    "mui-avatar": {
        component: "mui/basic/Avatar.js",
        label: "Avatar",
        order: 43
    },
    "mui-avatar-group": {
        component: "mui/basic/AvatarGroup.js",
        label: "AvatarGroup",
        order: 43
    },
    "mui-backdrop": {
        component: "mui/basic/Backdrop.js",
        label: "Backdrop",
        order: 43
    },
    "mui-badge": {
        component: "mui/basic/Badge.js",
        label: "Badge",
        order: 43
    },
    "mui-bottom-navigation": {
        component: "mui/basic/BottomNavigation.js",
        label: "BottomNavigation",
        order: 43
    },
    "mui-bottom-navigation-action": {
        component: "mui/basic/BottomNavigationAction.js",
        label: "BottomNavigationAction",
        order: 43
    },
    "mui-box": {
        component: "mui/basic/Box.js",
        label: "Box",
        order: 43
    },
    "mui-breadcrumbs": {
        component: "mui/basic/Breadcrumbs.js",
        label: "Breadcrumbs",
        order: 43
    },
    "mui-button": {
        component: "mui/basic/Button.js",
        label: "Button",
        order: 43
    },
    "mui-button-base": {
        component: "mui/basic/ButtonBase.js",
        label: "ButtonBase",
        order: 43
    },
    "mui-button-group": {
        component: "mui/basic/ButtonGroup.js",
        label: "ButtonGroup",
        order: 43
    },
    "mui-card": {
        component: "mui/basic/Card.js",
        label: "Card",
        order: 43
    },
    "mui-card-action-area": {
        component: "mui/basic/CardActionArea.js",
        label: "CardActionArea",
        order: 43
    },
    "mui-card-actions": {
        component: "mui/basic/CardActions.js",
        label: "CardActions",
        order: 43
    },
    "mui-card-content": {
        component: "mui/basic/CardContent.js",
        label: "CardContent",
        order: 43
    },
    "mui-card-header": {
        component: "mui/basic/CardHeader.js",
        label: "CardHeader",
        order: 43
    },
    "mui-card-media": {
        component: "mui/basic/CardMedia.js",
        label: "CardMedia",
        order: 43
    },
    "mui-checkbox": {
        component: "mui/basic/Checkbox.js",
        label: "Checkbox",
        order: 43
    },
    "mui-chip": {
        component: "mui/basic/Chip.js",
        label: "Chip",
        order: 43
    },
    "mui-circular-progress": {
        component: "mui/basic/CircularProgress.js",
        label: "CircularProgress",
        order: 43
    },
    "mui-collapse": {
        component: "mui/basic/Collapse.js",
        label: "Collapse",
        order: 43
    },
    "mui-container": {
        component: "mui/basic/Container.js",
        label: "Container",
        order: 43
    },
    "mui-css-baseline": {
        component: "mui/basic/CssBaseline.js",
        label: "CssBaseline",
        order: 43
    },
    "mui-data-table": {
        component: "mui/basic/DataTable.js",
        label: "DataTable",
        order: 43
    },
    "mui-dialog": {
        component: "mui/basic/Dialog.js",
        label: "Button",
        order: 43
    },
    "mui-dialog-actions": {
        component: "mui/basic/DialogActions.js",
        label: "DialogActions",
        order: 43
    },
    "mui-dialog-content": {
        component: "mui/basic/DialogContent.js",
        label: "DialogContent",
        order: 43
    },
    "mui-dialog-content-text": {
        component: "mui/basic/DialogContentText.js",
        label: "DialogContentText",
        order: 43
    },
    "mui-dialog-title": {
        component: "mui/basic/DialogTitle.js",
        label: "DialogTitle",
        order: 43
    },
    "mui-divider": {
        component: "mui/basic/Divider.js",
        label: "Divider",
        order: 43
    },
    "mui-drawer": {
        component: "mui/basic/Drawer.js",
        label: "Drawer",
        order: 43
    },
    "mui-fab": {
        component: "mui/basic/Fab.js",
        label: "Fab",
        order: 43
    },
    "mui-filled-input": {
        component: "mui/basic/FilledInput.js",
        label: "FilledInput",
        order: 43
    },
    "mui-form-control": {
        component: "mui/basic/FormControl.js",
        label: "FormControl",
        order: 43
    },
    "mui-form-control-label": {
        component: "mui/basic/FormControlLabel.js",
        label: "FormControlLabel",
        order: 43
    },
    "mui-form-group": {
        component: "mui/basic/FormGroup.js",
        label: "FormGroup",
        order: 43
    },
    "mui-form-label": {
        component: "mui/basic/FormLabel.js",
        label: "FormLabel",
        order: 43
    },
    "mui-grid": {
        component: "mui/basic/Grid.js",
        label: "Grid",
        order: 43
    },
    "mui-icon-button": {
        component: "mui/basic/IconButton.js",
        label: "IconButton",
        order: 43
    },
    "mui-image-list": {
        component: "mui/basic/ImageList.js",
        label: "ImageList",
        order: 43
    },
    "mui-image-list-item": {
        component: "mui/basic/ImageListItem.js",
        label: "ImageListItem",
        order: 43
    },
    "mui-image-list-item-bar": {
        component: "mui/basic/ImageListItemBar.js",
        label: "ImageListItemBar",
        order: 43
    },
    "mui-input": {
        component: "mui/basic/Input.js",
        label: "Input",
        order: 43
    },
    "mui-input-adornment": {
        component: "mui/basic/InputAdornment.js",
        label: "InputAdornment",
        order: 43
    },
    "mui-input-base": {
        component: "mui/basic/InputBase.js",
        label: "InputBase",
        order: 43
    },
    "mui-input-label": {
        component: "mui/basic/InputLabel.js",
        label: "InputLabel",
        order: 43
    },
    "mui-linear-progress": {
        component: "mui/basic/LinearProgress.js",
        label: "LinearProgress",
        order: 43
    },
    "mui-link": {
        component: "mui/basic/Link.js",
        label: "Link",
        order: 43
    },
    "mui-list": {
        component: "mui/basic/List.js",
        label: "List",
        order: 43
    },
    "mui-list-item": {
        component: "mui/basic/ListItem.js",
        label: "ListItem",
        order: 43
    },
    "mui-list-item-avatar": {
        component: "mui/basic/ListItemAvatar.js",
        label: "ListItemAvatar",
        order: 43
    },
    "mui-list-item-button": {
        component: "mui/basic/ListItemButton.js",
        label: "ListItemButton",
        order: 43
    },
    "mui-list-item-icon": {
        component: "mui/basic/ListItemIcon.js",
        label: "ListItemIcon",
        order: 43
    },
    "mui-list-item-secondary-action": {
        component: "mui/basic/ListItemSecondaryAction.js",
        label: "ListItemSecondaryAction",
        order: 43
    },
    "mui-list-item-text": {
        component: "mui/basic/ListItemText.js",
        label: "ListItemText",
        order: 43
    },
    "mui-list-sub-header": {
        component: "mui/basic/ListSubHeader.js",
        label: "ListSubHeader",
        order: 43
    },
    "mui-menu": {
        component: "mui/basic/Menu.js",
        label: "Menu",
        order: 43
    },
    "mui-menu-item": {
        component: "mui/basic/MenuItem.js",
        label: "MenuItem",
        order: 43
    },
    "mui-menu-list": {
        component: "mui/basic/MenuList.js",
        label: "MenuList",
        order: 43
    },
    "mui-mobile-stepper": {
        component: "mui/basic/MobileStepper.js",
        label: "MobileStepper",
        order: 43
    },
    "mui-modal": {
        component: "mui/basic/Modal.js",
        label: "Modal",
        order: 43
    },
    "mui-native-select": {
        component: "mui/basic/NativeSelect.js",
        label: "NativeSelect",
        order: 43
    },
    "mui-outlined-input": {
        component: "mui/basic/OutlinedInput.js",
        label: "OutlinedInput",
        order: 43
    },
    "mui-pagination": {
        component: "mui/basic/Pagination.js",
        label: "Pagination",
        order: 43
    },
    "mui-pagination-item": {
        component: "mui/basic/PaginationItem.js",
        label: "PaginationItem",
        order: 43
    },
    "mui-paper": {
        component: "mui/basic/Paper.js",
        label: "Paper",
        order: 43
    },
    "mui-popover": {
        component: "mui/basic/Popover.js",
        label: "Popover",
        order: 43
    },
    "mui-popper": {
        component: "mui/basic/Popper.js",
        label: "Popper",
        order: 43
    },
    "mui-radio": {
        component: "mui/basic/Radio.js",
        label: "Radio",
        order: 43
    },
    "mui-radio-group": {
        component: "mui/basic/RadioGroup.js",
        label: "RadioGroup",
        order: 43
    },
    "mui-rating": {
        component: "mui/basic/Rating.js",
        label: "Rating",
        order: 43
    },
    "mui-scoped-css-baseline": {
        component: "mui/basic/ScopedCssBaseline.js",
        label: "ScopedCssBaseline",
        order: 43
    },
    "mui-select": {
        component: "mui/basic/Select.js",
        label: "Select",
        order: 43
    },
    "mui-skeleton": {
        component: "mui/basic/Skeleton.js",
        label: "Skeleton",
        order: 43
    },
    "mui-slider": {
        component: "mui/basic/Slider.js",
        label: "Slider",
        order: 43
    },
    "mui-snack-bar": {
        component: "mui/basic/SnackBar.js",
        label: "SnackBar",
        order: 43
    },
    "mui-snack-bar-content": {
        component: "mui/basic/SnackBarContent.js",
        label: "SnackBarContent",
        order: 43
    },
    "mui-speed-dial": {
        component: "mui/basic/SpeedDial.js",
        label: "SpeedDial",
        order: 43
    },
    "mui-speed-dial-action": {
        component: "mui/basic/SpeedDialAction.js",
        label: "SpeedDialAction",
        order: 43
    },
    "mui-speed-dial-icon": {
        component: "mui/basic/SpeedDialIcon.js",
        label: "SpeedDialIcon",
        order: 43
    },
    "mui-stack": {
        component: "mui/basic/Stack.js",
        label: "Stack",
        order: 43
    },
    "mui-step": {
        component: "mui/basic/Step.js",
        label: "Step",
        order: 43
    },
    "mui-step-button": {
        component: "mui/basic/StepButton.js",
        label: "StepButton",
        order: 43
    },
    "mui-step-connector": {
        component: "mui/basic/StepConnector.js",
        label: "StepConnector",
        order: 43
    },
    "mui-step-content": {
        component: "mui/basic/StepContent.js",
        label: "StepContent",
        order: 43
    },
    "mui-step-icon": {
        component: "mui/basic/StepIcon.js",
        label: "StepIcon",
        order: 43
    },
    "mui-step-label": {
        component: "mui/basic/StepLabel.js",
        label: "StepLabel",
        order: 43
    },
    "mui-stepper": {
        component: "mui/basic/Stepper.js",
        label: "Stepper",
        order: 43
    },
    "mui-svg-icon": {
        component: "mui/basic/SvgIcon.js",
        label: "SvgIcon",
        order: 43
    },
    "mui-swipeable-drawer": {
        component: "mui/basic/SwipeableDrawer.js",
        label: "SwipeableDrawer",
        order: 43
    },
    "mui-switch": {
        component: "mui/basic/Switch.js",
        label: "Switch",
        order: 43
    },
    "mui-tab": {
        component: "mui/basic/Tab.js",
        label: "Tab",
        order: 43
    },
    "mui-table": {
        component: "mui/basic/Table.js",
        label: "Table",
        order: 43
    },
    "mui-table-body": {
        component: "mui/basic/TableBody.js",
        label: "TableBody",
        order: 43
    },
    "mui-table-cell": {
        component: "mui/basic/TableCell.js",
        label: "TableCell",
        order: 43
    },
    "mui-table-container": {
        component: "mui/basic/TableContainer.js",
        label: "TableContainer",
        order: 43
    },
    "mui-table-footer": {
        component: "mui/basic/TableFooter.js",
        label: "TableFooter",
        order: 43
    },
    "mui-table-head": {
        component: "mui/basic/TableHead.js",
        label: "TableHead",
        order: 43
    },
    "mui-table-pagination": {
        component: "mui/basic/TablePagination.js",
        label: "TablePagination",
        order: 43
    },
    "mui-table-row": {
        component: "mui/basic/TableRow.js",
        label: "TableRow",
        order: 43
    },
    "mui-table-sort-label": {
        component: "mui/basic/TableSortLabel.js",
        label: "TableSortLabel",
        order: 43
    },
    "mui-tabs": {
        component: "mui/basic/Tabs.js",
        label: "Tabs",
        order: 43
    },
    "mui-text-field": {
        component: "mui/basic/TextField.js",
        label: "TextField",
        order: 43
    },
    "mui-toggle-button": {
        component: "mui/basic/ToggleButton.js",
        label: "ToggleButton",
        order: 43
    },
    "mui-toggle-button-group": {
        component: "mui/basic/ToggleButtonGroup.js",
        label: "ToggleButtonGroup",
        order: 43
    },
    "mui-toolbar": {
        component: "mui/basic/Toolbar.js",
        label: "Toolbar",
        order: 43
    },
    "mui-tooltip": {
        component: "mui/basic/Tooltip.js",
        label: "Tooltip",
        order: 43
    },
    "mui-typography": {
        component: "mui/basic/Typography.js",
        label: "Typography",
        order: 43
    }
}