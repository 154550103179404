import React, { useEffect } from "react";
import { componentLookup } from "./commons/WebcomponentRegistry";

const App = () => {
  useEffect(() => {
    const importComponents = async () => {
      let result = {};
      for (const componentTag of Object.keys(componentLookup)) {
        const componentDef = await import(
          /* webpackChunkName: "[request]" */
          "./components/" + componentLookup[componentTag].component
        );
        result[componentTag] = {
          props: componentDef.props,
          styles: componentDef.styles,
          dispatchEvents: componentDef.dispatchEvents,
        };
      }
    };

    importComponents();
  }, []);

  return null;
};

export default App;