import axios from "axios";

export const http = {
    request: axios.request,
    get: axios.get,
    post: axios.post, 
    put: axios.put, 
    patch: axios.patch,
    options: axios.options,
    head: axios.head, 
    delete: axios.delete,
    getUri: axios.getUri
}